import { jsonRPC } from '@/api/api';
import Guid from 'devextreme/core/guid';
import { deepCopy } from '../../../utils/deep';
import componentActions from './components/actions';
import dictionaryActions from './dictionaries/actions';
import { loadGroupLayers } from './components/Gis/actions';
import { SET_ITEMS_AND_OPTIONS, SUBSCRIBE, UNSUBSCRIBE, SET_CONSTRUCTOR_ACTIVE_COMPONENT_FIELD } from '@/store/modules/config/mutations';
import { CONTAINER_COMPONENT_TYPES, REGEXP_GUID } from '@/utils/const';
import notify from 'devextreme/ui/notify';
import { callEventAction as callComponentEventAction } from './components/callEventAction';
import ComponentsConstructorOptions from '@/models/ComponentsConstructorOptions';
import { setPropByPath } from '@/utils/common';

export const subscribe = `subscribe`;
export const unsubscribe = `unsubscribe`;
export const broadcast = `broadcast`;
export const saveConstructorActiveComponent = 'saveConstructorActiveComponent';
export const setConstructorActiveComponent = 'setConstructorActiveComponent';
export const insertComponent = 'insertComponent';
export const updateComponent = 'updateComponent';
export const deleteComponents = 'deleteComponents';
export const callEventAction = 'callEventAction';

const TYPES_IDS = {
  GisLayer: 160,
  GisGroupLayer: 161,
  DataSet: 107,
  gisTimeline: 247
};

const actions = {
  ...componentActions,
  ...dictionaryActions,

  clearPage(context, { pageUrl }) {
    if (!pageUrl || !context.state.pages[pageUrl]) {
      return;
    }

    const items = context.state.pages[pageUrl].items;

    items.forEach((itemId) => {
      context.commit('clearComponent', {
        componentId: itemId
      });
    });

    context.commit('clearPage', { pageUrl });
  },

  loadPageConfig(context, { pageUrl, oldPageUrl, domain, device, urlParams }) {
    let constructorActive = context.rootState.uiConstructor.active;
    let params = {
      url: pageUrl,
      is_not_layers: true,
      domain,
      device,
      urlParams,
      is_constructor: constructorActive
      //stop_component_id: 110785 //Gis dom-map
      //stop_component_id: 118272 //Gis agro-map
    };
    return jsonRPC(process.env.VUE_APP_API_SERVICE, 'CONFIG.DSCONFIG.GETPAGE', params)
      .then((data) => {
        //Временный костыль, пока это не сделают на стороне бэка
        //Если для данного устройства отсутствуют компоненты, запросим компоненты для desktop (только в пользовательском режиме)
        const constructorActive = context.rootState.uiConstructor.active;
        if (!constructorActive && device !== 'desktop' && (!data.components || !Object.values(data.components).length)) {
          params.device = 'desktop';
          return jsonRPC(process.env.VUE_APP_API_SERVICE, 'CONFIG.DSCONFIG.GETPAGE', params);
        }
        return data;
      })
      .then((data) => {
        const pageConfig = data || {};
        const components = pageConfig.components || {};
        const cssStyle = pageConfig.cssStyle || {};
        const items = (pageConfig.items || []).filter((itemId) => components.hasOwnProperty(itemId));
        const eventAction = pageConfig.eventAction || [];
        const place = pageConfig.place || {};

        const masterplace_name = context.rootState.session?.userData?.masterplace_name;
        //Сбрасываем place.name для шапки
        if (masterplace_name === `/${pageUrl}`) {
          place.name = '';
        }

        if (oldPageUrl) {
          //context.commit('clearComponents', { url: oldPageUrl });
          context.commit('clearChanges');
        }

        context.commit(SET_ITEMS_AND_OPTIONS, { components, url: pageUrl, constructorActive: context.rootState.uiConstructor.active });
        context.commit('setPageCss', { url: pageUrl, cssStyle });
        context.commit('setPageItems', { url: pageUrl, itemIds: items });
        context.commit('setEventAction', { eventAction: eventAction || [] });
        context.commit('setPlace', { place });
        context.commit('setPageUrl', { pageUrl });
        context.commit('setDeviceType', { device });
        context.dispatch('emitLayerInitializedEvent', { components });
        context.dispatch(loadGroupLayers, { components });
      })
      .catch((error) => {
        if (error.json) {
          //TODO: обработка ошибок по нормальному
          window.console.log(error.statusText);
          error.json().then((errorData) => {
            window.console.log(errorData);
          });
        } else {
          window.console.error(error);
        }
        throw error;
      });
  },

  //param = { componentId, parentComponentId, componentPos = 0 + дополнительные (для pivotGrid) }
  loadComponent(context, inputParam) {
    const { componentId, parentComponentId, parentPopupId, componentPos = 0, keepExisting } = inputParam;

    const inputParamCopy = { ...inputParam };
    delete inputParamCopy.componentId;
    delete inputParamCopy.parentComponentId;
    delete inputParamCopy.componentPos;
    delete inputParamCopy.url; //Удалим параметр url, чтобы он не мешал запросу компонента
    delete inputParamCopy.keepExisting;
    let constructorActive = context.rootState.uiConstructor.active;

    const param = {
      ...inputParamCopy,
      _config_component_id: componentId,
      _config_dataset: 'CONFIG.DSCONFIG',
      is_constructor: constructorActive
    };

    return jsonRPC(process.env.VUE_APP_API_SERVICE, 'CONFIG.DSCONFIG.GETPAGE', param)
      .then((data) => {
        const components = data.components || {};
        const eventAction = data.eventAction || [];

        //Внимание! в мутацию передается url, если он есть.
        //Это сделано для того чтобы корневой компонент не терял свою страницу при перезагрузке после создания
        context.commit(SET_ITEMS_AND_OPTIONS, { components, url: inputParam.url || null, parentPopupId, keepExisting });
        context.commit('setEventAction', { eventAction: eventAction || [] });

        if (parentComponentId) {
          context.commit('setComponentParent', {
            componentId,
            componentPos,
            oldParentId: null,
            newParentId: parentComponentId,
            oldUrl: null,
            newUrl: null,
            device: null,
            changeConstructor: false,
            changeLoaded: false
          });
        }
        context.dispatch('emitLayerInitializedEvent', { components });
        return data;
      })
      .catch((err) => {
        window.console.log(err.message);
      });
  },

  setComponentParent(state, payload) {
    state.commit('setComponentParent', payload);
  },

  setParentPopupId(state, payload) {
    state.commit('setParentPopupId', payload);
  },

  createComponent(context, { componentType, componentPos, parentComponentId, url, device, componentName, options }) {
    const componentId = new Guid().toString();
    const componentConfig = {
      componentId,
      componentName: componentName || '<New component>',
      componentPos,
      componentType,
      parentComponentId,
      url,
      device,
      expanded: false,
      selected: false
    };

    if (options) {
      //options.componentType = componentType;
    }

    context.commit('createComponentConfig', { componentConfig, componentOptions: options });
    if (parentComponentId) {
      context.commit('addComponentToParent', { componentId, parentComponentId });
    } else if (url) {
      context.commit('addComponentToPage', { componentId, url });
    }

    return componentId;
  },

  copyComponentsToClipboard(context, { componentIds }) {
    localStorage.setItem('itemIdsClipboard', JSON.stringify(componentIds));
  },

  pasteComponentsFromClipboard(context, { position, parentId, url, device, configNew }) {
    //пакетный запрос к 'CONFIG.DSCONFIG'

    const componentIds = JSON.parse(localStorage.getItem('itemIdsClipboard'));
    const params = componentIds.map((componentId) => {
      return {
        _config_component_id: componentId,
        _config_dataset: 'CONFIG.DSCONFIG',
        stop_component_id: componentId,
        is_constructor: true
      };
    });

    return jsonRPC(process.env.VUE_APP_API_SERVICE, 'CONFIG.DSCONFIG.GETDATA', params).then((data) => {
      const componentConfigs = data[1]
        ? data.map((item) => {
            return Object.values(item[0].r_json.components)[0];
          })
        : Object.values(data[0].r_json.components);

      if (configNew && (configNew.drLeft || configNew.drTop)) {
        //Вставлено в точку. Установить координаты компонентов.
        setComponentsPosition(componentConfigs, configNew);
      }

      const promises = componentConfigs.map((componentConfig, componentIndex) => {
        //Вставка без потомков
        delete componentConfig.options.items;

        return context.dispatch(insertComponent, {
          componentConfig,
          componentType: componentConfig.componentType,
          componentPos: position + componentIndex * 100,
          parentComponentId: parentId,
          url,
          device,
          componentName: componentConfig.componentName,
          options: componentConfig.options
        });
      });
      return Promise.all(promises);
    });
  },

  /**ids - JSON массива идентификаторов: [123,567] */
  pasteFullComponentCopy(context, { parentComponentId, url, ids, device }) {
    let itemIds = JSON.parse(ids ? ids : localStorage.getItem('itemIdsClipboard'));
    const itemOptions = itemIds.map((itemId) => {
      return {
        ...(context.getters['getComponentOptions'](itemId) || {}),
        componentId: itemId
      };
    });

    itemOptions.forEach((options1) => {
      itemOptions.forEach((options2) => {
        if (options2.items && options2.items.includes(options1.componentId)) {
          const index = itemIds.indexOf(options1.componentId);
          itemIds.splice(index, 1);
        }
      });
    });

    let pos = null;

    if (parentComponentId) {
      pos = context.getters['lastItemPos'](parentComponentId) + 100;
    } else {
      pos = context.getters['getPageLastChildPosition'](url) + 100;
    }

    let constructorActive = context.rootState.uiConstructor.active;

    let param = {
      component_id: itemIds[0].toString(),
      url,
      device,
      parent_component_id: parentComponentId,
      pos: pos,
      _config_dataset: 'CONFIG.PRCLONECOMPONENT'
    };

    jsonRPC('getData', param)
      .then((result) => {
        notify({ message: 'Копирование завершено', width: 'auto' }, 'success', 3000);
        if (result[0] && result[0].r_component_id) {
          const fullCopyItemId = result[0].r_component_id.toString();

          jsonRPC(process.env.VUE_APP_API_SERVICE, 'CONFIG.DSCONFIG.GETPAGE', {
            _config_component_id: fullCopyItemId,
            _config_dataset: 'CONFIG.DSCONFIG',
            is_constructor: constructorActive
          }).then((data) => {
            const components = data.components || {};
            const eventAction = data.eventAction || [];

            context.commit(SET_ITEMS_AND_OPTIONS, { components });
            context.commit('setEventAction', { eventAction: eventAction || [] });

            let setParentPayload = {
              componentId: fullCopyItemId,
              changeLoaded: true
            };
            if (parentComponentId) {
              setParentPayload.newParentId = parentComponentId;
            } else {
              setParentPayload.newUrl = url;
            }
            setParentPayload.componentPos = pos || 0;
            context.commit('setComponentParent', setParentPayload);
            context.dispatch('emitLayerInitializedEvent', { components });
          });
        }
      })
      .finally(() => {
        context.commit('setLoading', false, { root: true });
      });
  },

  cloneComponent(context, { device }) {
    const activeComponentConfig = context.getters['getConstructorActiveComponentConfig'];

    let itemConfigCopy = {};
    let componentOptionsCopy = {};
    deepCopy(activeComponentConfig, itemConfigCopy);
    deepCopy(activeComponentConfig.options, componentOptionsCopy);

    return context.dispatch(insertComponent, {
      componentConfig: itemConfigCopy,
      ...itemConfigCopy,
      componentPos: itemConfigCopy.componentPos + 100,
      device,
      componentName: `${itemConfigCopy.componentName}_CLONE`,
      options: componentOptionsCopy
    });
  },

  [deleteComponents](context, { componentsId }) {
    const param = componentsId.map((componentId) => {
      return {
        _config_dataset: 'CONFIG.DSCOMPONENTS',
        id: componentId
      };
    });

    return jsonRPC('deleteData', param).then(() => {
      componentsId.map((componentId) => {
        const componentConfig = context.getters['getItem'](componentId);

        //Сбросим выделение с удаляемого компонента
        context.commit('clearComponentSelected', { componentId });

        //Удаление конфига компонента из словарей
        context.commit('deleteComponentConfig', { componentId });

        //Удаление ссылки на компонент из родителя
        context.commit('deleteComponentFromParent', {
          componentId,
          parentComponentId: componentConfig.parentComponentId,
          url: componentConfig.url
        });
      });
    });
  },

  /** Вызов события/действия компонента */
  callEventAction(context, { componentId, eventName, eventData }) {
    callComponentEventAction(context, { componentId, eventName, eventData });
  },

  /**Действие вызывается после загрузки компонентов слоев на карте*/
  emitLayerInitializedEvent(context, { components }) {
    if (components) {
      Object.keys(components).forEach((componentId) => {
        const item = components[componentId];
        if (item.componentType === 'GisLayer' || item.componentType === 'GisGroupLayer') {
          callComponentEventAction(context, {
            componentId: item.componentId,
            eventName: 'onConfigReady',
            eventData: {}
          });
        }
      });
    }
  },

  savePageStyle(context, { cssStyle, url }) {
    const param = {
      prop: cssStyle,
      url,
      _config_dataset: 'CONNECTIONS.DSPLACECSSSTYLE'
    };

    return jsonRPC('updateData', param).then(() => {
      context.commit('setPageCss', { url, cssStyle });
    });
  },

  clearChanges(context) {
    context.commit('clearChanges');
  },

  /**Установка в конструкторе активного компонента для редактирования
   * notUnselect - не развыделять кликнутый элемент, если он был выделен
   */
  [setConstructorActiveComponent](context, { componentId, keepSelected, notUnselect }) {
    context.commit('setComponentSelected', { componentId: componentId.toString(), keepSelected, notUnselect });
    const activeComponentId = context.getters['getConstructorActiveComponentId'];
    if (!activeComponentId || activeComponentId.match(REGEXP_GUID)) {
      context.commit('setConstructorActiveComponentConfig', { config: null });
      return;
    }

    return jsonRPC(process.env.VUE_APP_API_SERVICE, 'CONFIG.DSCONFIG.GETPAGE', {
      _config_component_id: activeComponentId,
      _config_dataset: 'CONFIG.DSCONFIG',
      stop_component_id: activeComponentId,
      is_constructor: true
    })
      .then((data) => {
        if (!data || !data.components || !data.components[activeComponentId]) {
          window.console.warn(`Не удалось загрузить настройки компонента: ${activeComponentId}`);
          return;
        }

        context.commit('setConstructorActiveComponentConfig', { activeComponentId, config: data.components[activeComponentId] });
      })
      .catch((err) => {
        window.console.log(err.message);
      });
  },

  [saveConstructorActiveComponent](context, { url }) {
    context.commit('setSavingProcess', { saving: true });

    //Установка урл страницы
    const config = context.state.constructorActiveComponentConfig;
    config.url = url;

    const param = prepareComponentConfigForSave(context, config);
    if (param.length === 0) {
      return Promise.resolve({
        requestData: param
      });
    }
    //Костыль для слоев карты, если они берутся из другой карты
    if (param.prop && param.prop.editorOptions && param.prop.editorOptions.parentGisId) {
      const parentGisId = param.prop.editorOptions.parentGisId;
      const parentComponentOptions = context.getters['getComponentOptions'](parentGisId);
      if (parentComponentOptions.linkedGisComponentId) {
        if (param.parent_id.toString() === parentGisId.toString()) {
          param.parent_id = +parentComponentOptions.linkedGisComponentId;
        }
      }
    }

    return jsonRPC('updateData', [param])
      .then((responseData) => {
        return {
          requestData: [param],
          responseData
        };
      })
      .finally(() => context.commit('setSavingProcess', { saving: false }));
  },

  [insertComponent](context, { componentConfig, componentType, componentPos, parentComponentId, url, device, componentName, options = {} }) {
    const configForInsert = {
      ...componentConfig,
      componentName: componentName || '<New component>',
      componentPos,
      componentType,
      parentComponentId,
      url,
      device,
      expanded: false,
      selected: false,
      options
    };

    const param = prepareComponentConfigForSave(context, configForInsert);
    return jsonRPC('insertData', [param]).then((result) => {
      configForInsert.componentId = result[0].id.toString();
      if (configForInsert.drLeft || configForInsert.drTop) {
        //Компоненты drag resize
        //выделяем вставленное
        configForInsert.isActive = true;
      }

      context.commit('createComponentConfig', {
        componentConfig: configForInsert,
        componentOptions: options
      });

      if (parentComponentId) {
        context.commit('addComponentToParent', { componentId: configForInsert.componentId, parentComponentId });
      } else if (url) {
        context.commit('addComponentToPage', { componentId: configForInsert.componentId, url });
      }

      return configForInsert.componentId;
    });
  },

  /**
   * Действие загрузит из базы конфиг компонента, применит изменения к конфигу и опциям и затем запустит сохранение компонента
   * @param {*} context
   * @param {*} param1
   */
  [updateComponent](context, { componentId, componentConfigChanges, componentOptionChanges, configFieldsChanges = [] }) {
    return jsonRPC(process.env.VUE_APP_API_SERVICE, 'CONFIG.DSCONFIG.GETPAGE', {
      _config_component_id: componentId,
      _config_dataset: 'CONFIG.DSCONFIG',
      stop_component_id: componentId,
      is_constructor: true
    })
      .then((data) => {
        if (!data || !data.components || !data.components[componentId]) {
          throw { message: `Не удалось загрузить настройки компонента: ${componentId}` };
        }

        //Применение значений из объектов
        let configForSave = {
          ...data.components[componentId],
          ...componentConfigChanges,
          options: {
            ...data.components[componentId].options,
            ...componentOptionChanges
          }
        };

        //Применение значений полей
        configFieldsChanges.forEach(({ fieldName, fieldValue }) => {
          setPropByPath(
            configForSave,
            fieldName.split(/\[|\]|\./).filter((item) => {
              return item !== '';
            }),
            fieldValue
          );
        });

        const param = prepareComponentConfigForSave(context, configForSave);
        if (param.length === 0) {
          return Promise.resolve({
            requestData: param
          });
        }

        //Костыль для слоев карты, если они берутся из другой карты
        if (param.prop && param.prop.editorOptions && param.prop.editorOptions.parentGisId) {
          const parentGisId = param.prop.editorOptions.parentGisId;
          const parentComponentOptions = context.getters['getComponentOptions'](parentGisId);
          if (parentComponentOptions.linkedGisComponentId) {
            if (param.parent_id.toString() === parentGisId.toString()) {
              param.parent_id = +parentComponentOptions.linkedGisComponentId;
            }
          }
        }

        //Запрет передавать слой и групповой слой без родителя
        if ([160, 161].includes(param.componenttype_id) && !param.parent_id) {
          notify({ message: 'Отсутствует идентификатор родительского компонента', width: 'auto' }, 'warning', 3000);
          window.console.warn('Отсутствует parent_id у слоя:', actionPayload.newParentId);
          return Promise.resolve();
        }

        return jsonRPC('updateData', [param]).then(() => {
          const activeComponentId = context.getters['getConstructorActiveComponentId'];
          if (activeComponentId === componentId) {
            //{ ...configForSave } - чтобы vuex заметил обновление свойств
            context.commit('setConstructorActiveComponentConfig', { config: { ...configForSave } });
            context.commit('setItemConfig', { config: configForSave });
            return;
          }
        });
      })
      .catch((err) => {
        window.console.log(err.message);
      });
  },

  setComponentSelected(context, { componentId, keepSelected }) {
    context.commit('setComponentSelected', { componentId, keepSelected });
  },

  setComponentLast(context, { componentId }) {
    context.commit('setComponentLast', { componentId });
  },

  clearSelection(context) {
    context.commit('clearSelection');
    context.commit('setConstructorActiveComponentConfig', { config: null });
  },

  /**
   * Универсальное действие установки полей в
   * @param {*} context
   * @param {*} param1
   */
  setItemFields(context, { componentId, fields, stateFields = ['items'] }) {
    fields.forEach((field) => {
      context.commit('setItemField', {
        componentId,
        fieldName: field.name,
        fieldValue: field.value,
        stateFields
      });
    });
  },

  /**
   * Установка свойств активного компонента конструктора
   * @param context
   * @param {*} param1
   */
  setConstructorActiveComponentFields(context, { fields }) {
    fields.forEach((field) => {
      context.commit(SET_CONSTRUCTOR_ACTIVE_COMPONENT_FIELD, {
        fieldName: field.name,
        fieldValue: field.value
      });
    });
  },

  /**
   * Простановка свойстви item компонента для конструирования
   * @param context
   * @param {*} param1
   */
  setConstructorItemFields(context, { componentId, fields }) {
    fields.forEach((field) => {
      context.commit('setItemField', {
        componentId,
        fieldName: field.name,
        fieldValue: field.value
      });
    });
  },

  /**
   * Установка отдельного поля в опциях для констуирования
   * @param {*} param0
   * @param {*} param1
   */
  setComponentConstructorOption({ commit }, { componentId, optionName, optionValue }) {
    commit('setComponentConstructorOption', { componentId, optionName, optionValue });
  },

  /**
   * Установка опции компонента не в режиме констуирования
   */
  setComponentOption(context, { componentId, optionName, optionValue }) {
    context.commit('setComponentOption', { componentId, optionName, optionValue });
  },

  clearComponent({ commit }, { componentId }) {
    commit('clearComponent', { componentId });
  },

  saveAccessRoleChanges(context, data) {
    context.commit('setSavingProcess', { saving: true });
    const param = {
      ...data,
      _config_dataset: 'CONFIG.DSCOMPONENTROLES'
    };

    return jsonRPC('updateData', param).finally(() => {
      context.commit('setSavingProcess', { saving: false });
    });
  },

  updateComponentOptions(context, { componentId, options }) {
    Object.entries(options).forEach(([optionName, optionValue]) => {
      context.commit('setComponentOption', {
        componentId,
        optionName,
        optionValue
      });
    });
    return componentId;
  },

  /**
   * Подписаться на событие компонента
   * @param commit
   * @param componentId - id компонента, на событие которого хотим подписаться
   * @param eventType - имя события
   * @param handler - callback
   */
  [subscribe]({ commit }, { componentId, eventType, handler }) {
    commit(SUBSCRIBE, { componentId, eventType, handler });
  },

  /**
   * Отписаться от события компонента
   * @param commit
   * @param componentId - id компонента, от события которого хотим отписаться
   * @param eventType - имя события
   * @param handler - callback
   */
  [unsubscribe]({ commit }, { componentId, eventType, handler }) {
    commit(UNSUBSCRIBE, { componentId, eventType, handler });
  },

  /**
   *
   * @param state - объект состояния Vuex
   * @param componentId - id компонента, вызвавшего событие
   * @param eventType - имя события
   * @param eventData - данные события
   */
  [broadcast]({ state }, { componentId, eventType, eventData = null }) {
    if (!state.observers[componentId] || !state.observers[componentId][eventType]) {
      return;
    }
    state.observers[componentId][eventType].forEach((handler) => {
      handler(eventData);
    });
  }
};

function prepareComponentConfigForSave(context, config) {
  let options = config.options;
  if (options && ComponentsConstructorOptions[config.componentType]) {
    options = new ComponentsConstructorOptions[config.componentType](options);
  }

  const configToSave = {
    ...config,
    options,
    _config_dataset: 'CONFIG.DSCOPONENTS'
  };

  //Простановка полей из словарей чтобы они не удалились
  const templates = context.rootState.uiConstructor.templates;
  const templateFields = context.rootState.uiConstructor.templateFields;
  const componentTypes = context.rootGetters['uiConstructor/componentTypes'];
  normalizeTemplateOptions(templates, configToSave, 'templateName', 'templateId');
  normalizeTemplateOptions(templateFields, configToSave, 'templateFieldName', 'templateFieldId');
  normalizeTemplateOptions(componentTypes, configToSave, 'componentType', 'componentTypeId');

  return normalizeComponentConfig(configToSave);
}

function normalizeTemplateOptions(dictionaryArr, componentConfig, fieldName, newFieldName) {
  if (Array.isArray(dictionaryArr) && componentConfig[fieldName]) {
    const dictionaryItem = dictionaryArr.filter((item) => item.name === componentConfig[fieldName])[0];
    if (dictionaryItem) {
      componentConfig[newFieldName] = dictionaryItem.id;
    }
  }
}

const PARENT_SPECIFIC_PROPS = [
  'visible',
  'label',
  'colSpan',
  'location',
  'title',
  'dataField',
  'dataFieldForCompare',
  'filterGroup',
  'toolbar',
  'col',
  'row',
  'rowSpan',
  'minColSpan',
  'minRowSpan',
  'maxColSpan',
  'maxRowSpan',
  'isNotDraggable',
  'isNotResizable',
  'inPanel',
  'panel',
  'layerComponentRole',
  'hideWhenCreate',
  'disabled',
  'drLeft',
  'drTop',
  'drWidth',
  'drHeight',
  'drMinw',
  'drMinh',
  'drDraggable',
  'drResizable',
  'drParentLimitation',
  'drSnapToGrid',
  'drAspectRatio',
  'drZindex',
  'drAxis'
];

const GANTT_SPECIFIC_PROPS = ['scaleType'];

const OPTIONS_FOR_DELETE = ['componentId', 'dataSetName'];

function normalizeComponentConfig(componentConfig) {
  let prop = {};
  prop.editorOptions = Object.assign({}, componentConfig.options);
  // Что бы при обновлении не терялись данные по площади и кол-ву
  if (componentConfig.layer_area) {
    prop.layer_area = componentConfig.layer_area;
  }

  if (componentConfig.layer_count) {
    prop.layer_count = componentConfig.layer_count;
  }

  PARENT_SPECIFIC_PROPS.forEach((optionName) => {
    prop[optionName] = componentConfig[optionName];
  });

  GANTT_SPECIFIC_PROPS.forEach((optionName) => {
    prop[optionName] = componentConfig[optionName];
  });

  if (componentConfig.cssStyle) {
    prop.cssStyle = componentConfig.cssStyle;
  }

  OPTIONS_FOR_DELETE.forEach((optionName) => {
    delete prop.editorOptions[optionName];
  });

  //Для компонентов-контейнеров удаляем свойство items
  if (CONTAINER_COMPONENT_TYPES.includes(componentConfig.componentType)) {
    delete prop.editorOptions.items;
  }

  let mappedConfig = {
    id: parseInt(componentConfig.componentId),
    parent_id: parseInt(componentConfig.parentComponentId),
    url: componentConfig.url,
    device: componentConfig.device,
    pos: componentConfig.componentPos,
    name: componentConfig.componentName,
    componenttype_id: Object.keys(TYPES_IDS).includes(componentConfig.componentType)
      ? TYPES_IDS[componentConfig.componentType]
      : componentConfig.componentTypeId,
    template_id: componentConfig.templateId || null,
    templatefield_id: componentConfig.templateFieldId || null,
    prop,
    _config_dataset: 'CONFIG.DSCOMPONENTS',
    dataset_component_id: componentConfig.dataSetComponentId
  };

  if (componentConfig.options.dataSetName) {
    const dataSetNameSplitted = componentConfig.options.dataSetName.split('.');
    mappedConfig.connection_code = dataSetNameSplitted[0];
    mappedConfig.dataset_code = dataSetNameSplitted[1];
  }

  return mappedConfig;
}

/**Установка координаты всех компонентов относительно точки вставки*/
function setComponentsPosition(componentConfigs, positionConfig) {
  //Нахождение левой верхней точки массива компонентов
  let topMin = Number.MAX_VALUE;
  let leftMin = Number.MAX_VALUE;
  componentConfigs.forEach((config) => {
    if (config.drLeft < leftMin) leftMin = config.drLeft ?? 0;
    if (config.drTop < topMin) topMin = config.drTop ?? 0;
  });
  //Расчет положения каждого компонента в списке
  componentConfigs.forEach((config) => {
    config.drLeft = (positionConfig.drLeft || 0) + (config.drLeft || 0) - leftMin;
    config.drTop = (positionConfig.drTop || 0) + (config.drTop || 0) - topMin;
  });
}

export default actions;
