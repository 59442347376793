import { jsonRPC } from '@/api/api';
import TMP_LAYER_CONFIG from '@/components/Gis/TmpLayer/GisTmpLayerConfig';
import { AnimatedCarsLayer } from '@/components/Gis/customLayers/animatedCarsLayer';

import { NO_PROXY_HOSTS, SCANEX_HOSTS } from '@/components/Gis/utils/const';
import { getWmsLegendGraphicUrl } from '@/components/Gis/utils/wms';

import CustomFilterFieldOptions from '@/models/CustomFilterFieldOptions';
import ReportImageLegend from '@/models/ReportImageLegend';
import { getToken as argGisGetToken, loadLayers as arcGisLoadLayers, loadLegend as arcGisLoadLegend } from '@/services/arcGis';

import { updateComponent } from '@/store/modules/config/actions';
import { SET_ITEMS_AND_OPTIONS } from '@/store/modules/config/mutations';
import { verifyVisibleLayersCount } from '@/store/modules/config/components/Gis/actions';
import { sortFilterFieldsByPos } from '@/utils/common';
import { getIconDataUrl } from '@/utils/paint';
import { bbox, buffer, area } from '@turf/turf';
import CustomStore from 'devextreme/data/custom_store';
import notify from 'devextreme/ui/notify';
import { callEventAction } from '../callEventAction';
import commonActions from '../commonActions';
import { getPropByPath, setPropByPath } from '@/utils/common';

import Guid from 'devextreme/core/guid';

import { getOptions as getDatasetOptions } from '../DataSet/getters';

import {
  createBufferZones as createBufferZonesDataset,
  deleteDataField as deleteDataSetDataField,
  deleteFilter as deleteDataSetFilter,
  exportData as exportDataSetData,
  getData,
  insertFilter as insertDataSetFilter,
  loadBounds as loadDataSetBounds,
  loadDataCount as loadDataSetDataCount,
  loadMinMaxDataValues,
  loadDataFields as loadDataSetDataFields,
  loadFilters as loadDataSetFilters,
  refreshFilters as refreshDataSetFilters,
  updateDataField as updateDataSetDataField,
  loadObjectLists as dataSetLoadObjectLists,
  loadFiltersById as dataSetLoadFiltersById
} from '../DataSet/actions';

import { SET_LOADED_DATA } from '../DataSet/mutations';
import { getMapbox } from '../Gis/getters';
import {
  addLayerToRecent,
  clearSelection as gisClearSelection,
  loadChildLayerComponents,
  loadLayerComponent,
  initLayersDefaultFilters,
  setEditingLayerComponentId
} from '../Gis/actions';
import {
  SET_BOUNDS,
  SET_CALC_FORM_OPTION,
  SET_SELECTED_MOUSE_TOOL,
  SET_TABLE_PANEL_LAYER_COMPONENT_ID,
  SET_FILTER_PANEL_LAYER_COMPONENT_ID
} from '../Gis/mutations';
import { SET_OPTION, SET_OPTION_BY_PATH } from '../mutations';
import {
  getCardComponentId,
  getChildLayerComponentIds,
  getDataSetName,
  getFilterObject,
  getLegendStyleItemIds,
  getLegendStyleItems,
  getOptions,
  getSelectedValidationIdsList,
  getTableComponentId,
  getStatsComponentId,
  getTimelineValue,
  getSelectedIds,
  getBounds as getLayerBounds,
  isLegendStyleItemHalfVisible,
  isVisible
} from './getters';
import {
  ADD_FEATURES,
  CLEAR_FEATURES,
  CLEAR_SELECTION,
  INIT_LEGEND_STYLE_ITEMS,
  PARSE_ARC_GIS_LAYERS,
  PARSE_ARC_GIS_LEGEND,
  REPAINT_LAYER,
  SET_ENABLE,
  SET_EXPANDED,
  SET_FEATURES,
  SET_FILTER,
  SET_FILTER_BUILDER_FIELDS,
  SET_FILTER_ID,
  SET_ITEMS,
  SET_LEGEND_STYLE_ITEM_VISIBLE,
  SET_LOADING,
  SET_MAPBOX_LAYERS,
  SET_SELECTED_IDS,
  SET_SELECTION_GEOJSON,
  SET_TEXT_VISIBLE,
  SET_VISIBLE,
  SET_WMS_LEGENDS,
  SET_FILTER_FIELD_VALUES,
  SET_COMPLEX_FILTER_VALUE,
  SET_FILTER_LIST_IDS,
  SET_STATISTIC_FILTER_KEYS,
  SET_CONSTRUCTOR_FILTER_VALUE,
  SET_EDIT_LAYER_OBJECT,
  setLayerSavedOption,
  SET_VALIDATION_LIST,
  SET_SELECTED_VALIDATION_IDS_LIST,
  SET_SELECTED_VALIDATION_IDS_LIST_FROM_DATASET,
  RESET_CHILD_COMPONENTS,
  SET_LEGEND_STYLE_ITEM_ID,
  SET_FAVORITE_POSITION
} from './mutations';
import { deepCopy } from '@/utils/deep';
import ComponentStore from '@/models/common/ComponentStore';
import { walkContours } from '@/components/Gis/utils/polygons';
import { OBJECT_IDENTIFICATION, SELECT } from '@/components/Gis/GisToolbar/GisToolbarButtonNames';

//Hазвания действий
const PREFIX = 'GisLayer';
export const setVisible = `${PREFIX}/setVisible`;
export const setTextVisible = `${PREFIX}/setTextVisible`;
export const setExpanded = `${PREFIX}/setExpanded`;
export const setEnable = `${PREFIX}/setEnable`;
export const setLegendStyleItemVisible = `${PREFIX}/setLegendStyleItemVisible`;
export const setOption = `${PREFIX}/setOption`;
export const loadBounds = `${PREFIX}/loadBounds`;
export const setSelectedIds = `${PREFIX}/setSelectedIds`;
export const setSelectionGeoJson = `${PREFIX}/setSelectionGeoJson`;
export const loadDataCount = `${PREFIX}/loadDataCount`;
export const loadMinMaxValues = `${PREFIX}/loadMinMaxValues`;
export const loadTotalDataCount = `${PREFIX}/loadTotalDataCount`;
export const loadDataFields = `${PREFIX}/loadDataFields`;
export const loadFilterFieldOptions = `${PREFIX}/loadFilterFieldOptions`;
export const loadFilterBuilderFields = `${PREFIX}/loadFilterBuilderFields`;
export const updateDataField = `${PREFIX}/updateDataField`;
export const deleteDataField = `${PREFIX}/deleteDataField`;
export const refreshFieldLayer = `${PREFIX}/refreshFieldLayer`;
export const loadChildComponents = `${PREFIX}/loadChildComponents`;
export const updateCardAndTable = `${PREFIX}/updateCardAndTable`;
export const rebuildForms = `${PREFIX}/rebuildForms`;
export const deleteLayerComponent = `${PREFIX}/deleteLayerComponent`;
export const exportData = `${PREFIX}/exportData`;
export const createBufferZones = `${PREFIX}/createBufferZones`;
export const cloneLayer = `${PREFIX}/cloneLayer`;
export const loadFeatureData = `${PREFIX}/loadFeatureData`;
export const loadFeatures = `${PREFIX}/loadFeatures`;
export const loadGeoJson = `${PREFIX}/loadGeoJson`;
export const loadValidationList = `${PREFIX}/loadValidationList`;
export const loadSelectedValidationList = `${PREFIX}/loadSelectedValidationList`;
export const loadArcGis = `${PREFIX}/loadArcGis`;
export const getWfsData = `${PREFIX}/getWfsData`;
export const uploadAndSetGeoJsonData = `${PREFIX}/uploadAndSetGeoJsonData`;
export const loadWms = `${PREFIX}/loadWms`;
export const clearSelection = `${PREFIX}/clearSelection`;
export const locate = `${PREFIX}/locate`;
export const layerValidation = `${PREFIX}/layerValidation`;
export const selectFeatures = `${PREFIX}/selectFeatures`;
export const loadLayerFilters = `${PREFIX}/loadLayerFilters`;
export const loadLayerFiltersById = `${PREFIX}/loadLayerFiltersById`;
export const loadStyleFilters = `${PREFIX}/loadStyleFilters`;
export const deleteFilter = `${PREFIX}/deleteFilter`;
export const saveFilter = `${PREFIX}/saveFilter`;
export const refreshFilters = `${PREFIX}/refreshFilters`;
export const changeFilter = `${PREFIX}/changeFilter`;
export const openLayerFilterPanel = `${PREFIX}/openLayerFilterPanel`;
export const setStatisticFilterValue = `${PREFIX}/setStatisticFilterValue`;
export const deleteStatisticFilterValuesByType = `${PREFIX}/deleteStatisticFilterValuesByType`;
export const showCard = `${PREFIX}/showCard`;
export const loadReportImageLegend = `${PREFIX}/loadReportImageLegend`;
export const applyFilter = `${PREFIX}/applyFilter`;
export const createAndApplyFilter = `${PREFIX}/createAndApplyFilter`;
export const setFilterConfig = `${PREFIX}/setFilterConfig`;
export const callFilterValueChangedEventAction = `${PREFIX}/callFilterValueChangedEventAction`;
export const callTablePopupFormReadyEventAction = `${PREFIX}/callTablePopupFormReadyEventAction`;
export const callStatsPopupFormReadyEventAction = `${PREFIX}/callStatsPopupFormReadyEventAction`;
export const callFeatureTooltipShowEventAction = `${PREFIX}/callFeatureTooltipShowEventAction`;
export const setStyleItem = `${PREFIX}/setStyleItem`;
export const updateMapboxLayers = `${PREFIX}/updateMapboxLayers`;
export const updateLayerOptions = `${PREFIX}/updateLayerOptions`;
export const gisLayerLoadChildLayerComponents = `${PREFIX}/gisLayerLoadChildLayerComponents`;
export const restoreVisible = `${PREFIX}/restoreVisible`;
export const repaintLayer = `${PREFIX}/repaintLayer`;
export const showTablePanel = `${PREFIX}/showTablePanel`;
export const setSearchFields = `${PREFIX}/setSearchFields`;
export const setSearchDataLoading = `${PREFIX}/setSearchDataLoading`;
export const openCalcForm = `${PREFIX}/openCalcForm`;
export const closeCalcForm = `${PREFIX}/closeCalcForm`;
export const refreshData = `${PREFIX}/refreshData`;
export const calcInterpolationLayers = `${PREFIX}/calcInterpolationLayers`;
export const editLayerObject = `${PREFIX}/editLayerObject`;
export const loadObjectLists = `${PREFIX}/loadObjectLists`;
export const showLayerTablePopup = `${PREFIX}/showLayerTablePopup`;
export const addLayerPolygon = `${PREFIX}/addLayerPolygon`;
export const fillMapExtent = `${PREFIX}/fillMapExtent`;
export const saveFavoritePosition = `${PREFIX}/saveFavoritePosition`;

export default {
  [setVisible](context, { componentId, visible }) {
    setLayerVisible(context, { componentId, visible, isRootCall: true });
    const { parentGisId, type } = context.getters[getOptions](componentId);

    if (visible) {
      //При включении видимости слоя, делаем видимым список правил
      context.commit(SET_OPTION, {
        componentId: componentId,
        optionName: 'styleListExpanded',
        optionValue: visible
      });

      //При включении видимости geoJson или wfs слоя надо вызвать загрузку данных
      context.dispatch(uploadAndSetGeoJsonData, {
        componentId
      });

      // Для слоев ПКК включаем идентификацию сразу
      if (type === 'pkk') {
        context.commit(SET_SELECTED_MOUSE_TOOL, {
          gisComponentId: parentGisId,
          buttonName: OBJECT_IDENTIFICATION
        });
      }
    } else {
      // При выключении слоя убираем все выделенные объекты
      context.commit(CLEAR_SELECTION, {
        layerComponentId: componentId
      });

      // Для слоев ПКК выключаем идентификацию сразу и переводим на обычное выделение
      if (type === 'pkk') {
        context.commit(SET_SELECTED_MOUSE_TOOL, {
          gisComponentId: parentGisId,
          buttonName: SELECT
        });
      }
    }
  },

  [restoreVisible](context, { componentId, layerComponentIds }) {
    const visible = layerComponentIds.includes(componentId);

    setLayerVisible(context, {
      componentId,
      visible,
      layerComponentIds
    });

    if (visible) {
      //При включении видимости слоя, делаем видимым список правил
      context.commit(SET_OPTION, {
        componentId: componentId,
        optionName: 'styleListExpanded',
        optionValue: visible
      });
    }
  },

  [showTablePanel](context, { componentId, filter }) {
    const { parentGisId } = context.getters[getOptions](componentId);
    //initLayersDefaultFilters
    return getLoadChildComponentsPromise(context, componentId).then(() => {
      context.commit(SET_TABLE_PANEL_LAYER_COMPONENT_ID, {
        gisComponentId: parentGisId,
        layerComponentId: componentId
      });
    });
  },

  [repaintLayer](context, { componentId, ...param }) {
    const { clearForced } = param;
    if (!context.getters[getOptions](componentId).singleFeature || clearForced) {
      context.commit(CLEAR_FEATURES, { layerComponentId: componentId });
    }
    context.commit(REPAINT_LAYER, { componentId });
  },

  [setTextVisible]({ commit }, { componentId, textVisible }) {
    commit(SET_TEXT_VISIBLE, { componentId, textVisible });
  },

  [setOption]({ commit }, { componentId, name, value }) {
    commit(SET_OPTION_BY_PATH, { componentId, optionName: name, optionValue: value });
  },

  [loadBounds]({ dispatch, getters }, { layerComponentId, ...param }) {
    const layerOptions = getters[getOptions](layerComponentId);
    //TODO: передать текущее значение фильтра const where = layerOptions.filter

    if (!layerOptions.dataSetComponentId) {
      return null;
    }

    return dispatch(loadDataSetBounds, {
      componentId: layerOptions.dataSetComponentId,
      param: {
        ...param
        //TODO: where
      }
    });
  },

  [setExpanded]({ commit, dispatch, getters }, { layerComponentId, expanded, saveToLocalStorage = true }) {
    const layerOptions = getters[getOptions](layerComponentId);

    return dispatch(gisLayerLoadChildLayerComponents, {
      componentId: layerComponentId,
      gisComponentId: layerOptions.parentGisId,
      forceHideLayers: true
    }).then(() => {
      commit(SET_EXPANDED, {
        componentId: layerComponentId,
        expanded,
        saveToLocalStorage
      });
    });
  },

  [setEnable]({ commit }, { componentId, enable }) {
    commit(SET_ENABLE, { componentId, enable });
  },

  [setLegendStyleItemVisible](context, { componentId, legendStyleItemId, visible }) {
    const { parentGisId } = context.getters[getOptions](componentId);
    if (visible && !verifyVisibleLayersCount(context, parentGisId, componentId)) {
      return;
    }

    context.commit(SET_LEGEND_STYLE_ITEM_VISIBLE, { layerComponentId: componentId, legendStyleItemId, visible });
    const legendStyleItemIds = context.getters[getLegendStyleItemIds](componentId);
    const { type } = context.getters[getOptions](componentId);
    const someVisible = legendStyleItemIds.some((legendStyleItemId) => {
      return context.getters[isLegendStyleItemHalfVisible](componentId, legendStyleItemId);
    });

    //Если хоть один стиль стиль видим, выделения не сбрасываются - так остаются выделенные объекты
    //Исправлено - если хоть один стиль скрывается - выделение сбрасывается
    if (!visible) {
      context.commit(CLEAR_SELECTION, {
        layerComponentId: componentId
      });

      // context.commit(SET_LEGEND_STYLE_ITEM_ID, {
      //   layerComponentId: componentId,
      //   legendStyleItemId: legendStyleItemId,
      //   legendStyleItemIds: legendStyleItemIds
      // });
    }

    if (type === 'tile') {
      context.commit(SET_LEGEND_STYLE_ITEM_ID, {
        layerComponentId: componentId,
        legendStyleItemId: legendStyleItemId,
        visible: visible,
        legendStyleItemIds: legendStyleItemIds
      });
    }

    //Если хоть один стиль видим, сохраняем, что слой видимый
    setLayerSavedOption(componentId, parentGisId, { visible: someVisible }, context.getters[getOptions](parentGisId));

    const legendStyleItems = context.getters[getLegendStyleItems](componentId);
    const clusterRule = legendStyleItems.find((legendStyleItem) => {
      return legendStyleItem.mapboxLayerConfigs?.[0]?.metadata?.isCluster;
    });

    // Если есть слой кластеризации, и при этом он не показывается в легенде, то дополнительно включаем его видимость
    if (clusterRule?.mapboxLayerConfigs?.[0].displayedInLegend === false) {
      context.commit(SET_LEGEND_STYLE_ITEM_VISIBLE, { layerComponentId: componentId, legendStyleItemId: clusterRule.id, visible: true });
    }
  },

  [setSelectedIds](context, { layerComponentId, ids }) {
    context.commit(SET_SELECTED_IDS, { componentId: layerComponentId, ids });
    const selectionGeoJson = context.getters[getOptions](layerComponentId).selectionGeoJson?.geoJson[0];
    callEventAction(context, {
      componentId: layerComponentId,
      eventName: 'onFeaturesSelect',
      eventData: {
        selectionGeoJson,
        selectedIds: context.getters[getSelectedIds](layerComponentId)
      }
    });
  },

  [setSelectionGeoJson]({ commit }, { layerComponentId, geoJson = null, ids = [], not_ids = [], features = [], shiftKey = false }) {
    commit(SET_SELECTION_GEOJSON, {
      componentId: layerComponentId,
      ids,
      not_ids,
      features,
      shiftKey,
      geoJson
    });
  },

  [selectFeatures](context, { layerComponentId, features, shiftKey = false }) {
    const { keyField } = context.getters[getOptions](layerComponentId);
    const featureIds = features.map((feature) => {
      return feature.properties[keyField];
    });

    context.commit(SET_SELECTED_IDS, {
      componentId: layerComponentId,
      ids: featureIds,
      shiftKey
    });

    context.commit(SET_SELECTION_GEOJSON, {
      componentId: layerComponentId,
      ids: [...featureIds],
      features,
      shiftKey
    });

    callEventAction(context, {
      componentId: layerComponentId,
      eventName: 'onFeaturesSelect',
      eventData: {
        selectionGeoJson: context.getters[getOptions](layerComponentId).selectionGeoJson,
        selectedIds: context.getters[getSelectedIds](layerComponentId)
      }
    });
  },

  [changeFilter](context, { componentId, filterValue }) {
    context.commit(SET_FILTER, { componentId, value: filterValue });

    //Сброс значения filterId
    context.commit(SET_FILTER_ID, { layerComponentId: componentId, filterId: null });

    callEventAction(context, {
      componentId,
      eventName: 'onFilterValueChanged',
      eventData: {
        filter_value: filterValue //Фильтр текущего слоя в формате devexpress
      }
    });
  },

  [deleteStatisticFilterValuesByType](context, { componentId, type }) {
    if (!type) {
      return;
    }

    const { statisticFilterKeys } = context.getters[getFilterObject](componentId);
    if (!statisticFilterKeys || !statisticFilterKeys.length) {
      return;
    }

    const result = [];
    statisticFilterKeys.forEach((item) => {
      if (type !== Object.keys(item)[0]) {
        result.push(item);
      }
    });

    context.commit(SET_STATISTIC_FILTER_KEYS, {
      layerComponentId: componentId,
      statisticFilterKeys: result
    });
  },

  [setStatisticFilterValue](context, { componentId, value }) {
    if (!value || typeof value !== 'object' || Object.keys(value).length === 0) {
      context.commit(SET_STATISTIC_FILTER_KEYS, {
        layerComponentId: componentId,
        statisticFilterKeys: null
      });
      return;
    }

    const filterObject = context.getters[getFilterObject](componentId);
    const statisticFilterKeys = filterObject.statisticFilterKeys || [];
    const valueIndex = statisticFilterKeys.findIndex((item) => {
      const valueEntry = Object.entries(value)[0];
      if (item[valueEntry[0]] === valueEntry[1]) {
        return true;
      }
      return false;
    });

    if (valueIndex === -1) {
      statisticFilterKeys.push(value);
    } else {
      statisticFilterKeys.splice(valueIndex, 1);
    }

    context.commit(SET_STATISTIC_FILTER_KEYS, {
      layerComponentId: componentId,
      statisticFilterKeys
    });
  },

  [openLayerFilterPanel](context, { componentId }) {
    const { parentGisId } = context.getters[getOptions](componentId);

    return context
      .dispatch(loadFilterBuilderFields, {
        layerComponentId: componentId,
        gisComponentId: parentGisId,
        hardRefresh: false
      })
      .then(() => {
        context.commit(SET_FILTER_PANEL_LAYER_COMPONENT_ID, {
          gisComponentId: parentGisId,
          layerComponentId: componentId
        });
      });
  },

  [callFilterValueChangedEventAction](context, { layerComponentId }) {
    const { filterId, filter, filterValue } = context.getters[getOptions](layerComponentId);

    //TODO: добавить вшитое в настройки значение where

    callEventAction(context, {
      componentId: layerComponentId,
      eventName: 'onFilterValueChanged',
      eventData: {
        filter_id: filterId,
        filter_value: filterValue, //Фильтр текущего слоя в формате devexpress
        filter //Словарь для фильтрации по параметрам других слоев с пересечением
      }
    });
  },

  [callTablePopupFormReadyEventAction](context, { layerComponentId, ...params }) {
    const tableComponentId = context.getters[getTableComponentId](layerComponentId);
    let eventData = context.getters['getParamValue'](tableComponentId, 'selectedData');
    eventData = { ...eventData, ...params };

    return callEventAction(context, {
      componentId: tableComponentId,
      eventName: 'onPopupFormReady',
      eventData
    });
  },

  [callStatsPopupFormReadyEventAction](context, { layerComponentId, ...params }) {
    const statsComponentId = context.getters[getStatsComponentId](layerComponentId);
    let eventData = context.getters['getParamValue'](statsComponentId, 'selectedData');
    eventData = { ...eventData, ...params };

    return callEventAction(context, {
      componentId: statsComponentId,
      eventName: 'onPopupFormReady',
      eventData
    });
  },

  [callFeatureTooltipShowEventAction](context, { layerComponentId, featureData }) {
    return callEventAction(context, {
      componentId: layerComponentId,
      eventName: 'onFeatureTooltipShown',
      eventData: featureData
    });
  },

  [loadDataCount](context, { layerComponentId }) {
    const layerOptions = context.getters[getOptions](layerComponentId);

    let param = {};
    if (layerOptions.filterId) {
      param._config_filter_id = layerOptions.filterId;
    } else {
      param.where = layerOptions.filterValue;
      param.filter = layerOptions.filter;
    }

    return getLoadChildComponentsPromise(context, layerComponentId).then(() => {
      return context.dispatch(loadDataSetDataCount, {
        componentId: layerOptions.dataSetComponentId,
        ...param
      });
    });
  },

  [loadMinMaxValues](context, { layerComponentId, selector }) {
    const layerOptions = context.getters[getOptions](layerComponentId);
    const param = {};
    if (layerOptions.params?.cubeworkspace_id) {
      param.cubeworkspace_id = layerOptions.params.cubeworkspace_id;
      param.elementtype_id = '13';
      param._config_is_olap = true;
    }
    return context.dispatch(loadMinMaxDataValues, { dataSetComponentId: layerOptions.dataSetComponentId, selector, ...param });
  },

  [loadTotalDataCount](context, { layerComponentId }) {
    const layerOptions = context.getters[getOptions](layerComponentId);

    return getLoadChildComponentsPromise(context, layerComponentId).then(() => {
      return context.dispatch(loadDataSetDataCount, {
        componentId: layerOptions.dataSetComponentId
      });
    });
  },

  [loadDataFields](context, { layerComponentId, gisComponentId, refresh = false }) {
    return context
      .dispatch(loadLayerComponent, {
        gisComponentId,
        layerComponentId,
        rootGisComponentId: gisComponentId
      })
      .then(() => {
        const layerOptions = context.getters[getOptions](layerComponentId);
        if (layerOptions.params?.cubeworkspace_id) {
          //Это слой табло, загрузить поля из особого датасета
          const params = {
            cubeworkspace_id: layerOptions.params.cubeworkspace_id,
            _config_dataset: 'CONFIG.PRMAP_CUBEWORKSPACE_FIELDS'
          };
          return jsonRPC('getData', params);
        } else {
          return getLoadChildComponentsPromise(context, layerComponentId).then(() => {
            if (!layerOptions.dataSetComponentId) {
              return [];
            }
            //layerOptions.params используется в табло
            const additionalParams = layerOptions.params || {};
            return context.dispatch(loadDataSetDataFields, {
              componentId: layerOptions.dataSetComponentId,
              additionalParams,
              refresh
            });
          });
        }
      });
  },

  [updateDataField](context, { layerComponentId, fields }) {
    const layerOptions = context.getters[getOptions](layerComponentId);

    return getLoadChildComponentsPromise(context, layerComponentId).then(() => {
      if (!layerOptions.dataSetComponentId) {
        return null;
      }

      return context.dispatch(updateDataSetDataField, {
        componentId: layerOptions.dataSetComponentId,
        fields
      });
    });
  },

  [deleteDataField](context, { layerComponentId, fieldIds }) {
    const layerOptions = context.getters[getOptions](layerComponentId);

    return getLoadChildComponentsPromise(context, layerComponentId).then(() => {
      if (!layerOptions.dataSetComponentId) {
        return null;
      }

      return context.dispatch(deleteDataSetDataField, {
        componentId: layerOptions.dataSetComponentId,
        fieldIds
      });
    });
  },

  /**Серверная операция обновления списка полей в векторном слое */
  [refreshFieldLayer](context, { layerComponentId, fieldname }) {
    const layerOptions = context.getters[getOptions](layerComponentId);
    if (!layerOptions.dataSetComponentId) {
      return Promise.resolve(null);
    }
    return getLoadChildComponentsPromise(context, layerComponentId).then(() => {
      const dataSetName = context.getters[getDataSetName](layerComponentId);
      if (!dataSetName) {
        return null;
      }
      const params = {
        component_id: layerComponentId,
        fieldname: fieldname,
        connection_dataset_code: dataSetName,
        _config_dataset: 'CONFIG.PRREFRESHFIELDLAYER'
      };
      return jsonRPC('getData', params);
    });
  },

  [loadFilterFieldOptions](context, { layerComponentId, gisComponentId, hardRefresh = false }) {
    const { filterFieldOptions } = context.getters[getOptions](layerComponentId);

    if (filterFieldOptions && !hardRefresh) {
      return filterFieldOptions;
    }

    return context
      .dispatch(loadDataFields, {
        layerComponentId,
        gisComponentId
      })
      .then((dataFields) => {
        const filterFieldOptions = filterFieldOptionsFactory(dataFields);

        context.commit(SET_OPTION, {
          componentId: layerComponentId,
          optionName: 'filterFieldOptions',
          optionValue: filterFieldOptions
        });

        return filterFieldOptions;
      });
  },

  [loadFilterBuilderFields](context, { layerComponentId, gisComponentId, hardRefresh = false }) {
    return context
      .dispatch(loadFilterFieldOptions, {
        layerComponentId,
        gisComponentId,
        hardRefresh
      })
      .then((filterFieldOptions) => {
        const filterBuilderFields = filterBuilderFieldsFactory(Object.values(filterFieldOptions));

        context.commit(SET_FILTER_BUILDER_FIELDS, {
          layerComponentId,
          filterBuilderFields
        });

        return filterBuilderFields;
      });
  },

  [loadLayerFilters](context, { layerComponentId }) {
    return loadFilters(context, layerComponentId).then((data) => {
      // 1 - список фильтров слоя; 2 - список фильтров стилей
      return data.filter((item) => item.filtergroup_id === 1);
    });
  },

  [loadLayerFiltersById](context, { layerComponentId, filterId }) {
    const layerOptions = context.getters[getOptions](layerComponentId);
    return context.dispatch(dataSetLoadFiltersById, {
      componentId: layerOptions.dataSetComponentId,
      filterId: filterId
    });
  },

  [loadStyleFilters](context, { layerComponentId }) {
    return loadFilters(context, layerComponentId).then((data) => {
      // 1 - список фильтров слоя; 2 - список фильтров стилей
      return data.filter((item) => item.filtergroup_id === 2);
    });
  },

  [deleteFilter](context, { layerComponentId, filterId }) {
    const layerOptions = context.getters[getOptions](layerComponentId);

    return getLoadChildComponentsPromise(context, layerComponentId).then(() => {
      return context.dispatch(deleteDataSetFilter, {
        componentId: layerOptions.dataSetComponentId,
        filterId
      });
    });
  },

  [saveFilter](context, { layerComponentId, filterName }) {
    const { dataSetComponentId, filterFieldValues, complexFilterValue, filterListIds, statisticFilterKeys, constructorFilterValue, constructorFilterParams } =
      context.getters[getOptions](layerComponentId);

    //TODO: разобраться с форматом сохранения, сейчас бэк заменяет "{}" на "[]"

    return getLoadChildComponentsPromise(context, layerComponentId).then(() => {
      return loadFilters(context, layerComponentId).then(() => {
        return context.dispatch(insertDataSetFilter, {
          componentId: dataSetComponentId,
          layerComponentId,
          body: {
            layerComponentId,
            filter: filterFieldValues,
            complexFilter: complexFilterValue,
            list_ids: filterListIds,
            _static_filter: statisticFilterKeys,
            constructorFilter: constructorFilterValue,
            params: constructorFilterParams
          },
          name: filterName,
          filtergroup_id: 1 // 1 - список фильтров слоя; 2 - список фильтров стилей
        });
      });
    });
  },

  [refreshFilters](context, { layerComponentId }) {
    const layerOptions = context.getters[getOptions](layerComponentId);

    return getLoadChildComponentsPromise(context, layerComponentId).then(() => {
      return context.dispatch(refreshDataSetFilters, {
        componentId: layerOptions.dataSetComponentId
      });
    });
  },

  [loadChildComponents](context, { layerComponentId }) {
    return getLoadChildComponentsPromise(context, layerComponentId);
  },

  [updateCardAndTable](context, { layerComponentId }) {
    context.commit(RESET_CHILD_COMPONENTS, { layerComponentId });

    const cardComponentId = context.getters[getCardComponentId](layerComponentId);
    const tableComponentId = context.getters[getTableComponentId](layerComponentId);
    const { parentGisId } = context.getters[getOptions](layerComponentId);

    const uploadPromises = [];
    if (tableComponentId) {
      context.commit(SET_TABLE_PANEL_LAYER_COMPONENT_ID, {
        gisComponentId: parentGisId,
        layerComponentId: null
      });
      const instance = ComponentStore.instanceMap.get(tableComponentId);
      if (instance) {
        instance.$destroy();
      }
    }
    if (cardComponentId) {
      const instance = ComponentStore.instanceMap.get(cardComponentId);
      if (instance) {
        instance.$destroy();
      }
    }

    return Promise.all(uploadPromises);
  },

  [rebuildForms]({ getters }, { componentId }) {
    const dataSetName = getters[getDataSetName](componentId);

    return jsonRPC('getData', {
      _config_dataset: 'CONNECTIONS.PRDATASET_COMPONENT',
      dataset_code: dataSetName
    });
  },

  [exportData](context, { layerComponentId, selected, url, format, exportUserId, epsg, ...params }) {
    const layerOptions = context.getters[getOptions](layerComponentId);
    const keyField = layerOptions.keyField || 'id';
    const filterId = layerOptions.filterId;

    if (!layerOptions.dataSetComponentId) {
      //TODO: сообщение об ошибке?
      return null;
    }

    context.commit(SET_LOADING, {
      componentId: layerComponentId,
      value: true
    });

    let where = null;
    const ids = selected ? layerOptions.selectedIds || [] : null;
    if (ids && ids.length > 0) {
      where = ids.reduce((result, selectedId, index) => {
        if (index > 0) {
          result.push('or');
        }
        result.push([keyField, '=', selectedId]);

        return result;
      }, []);
      if (where.length === 1) {
        where = where[0];
      }
    }

    return getLoadChildComponentsPromise(context, layerComponentId)
      .then(() => {
        return context.dispatch(exportDataSetData, {
          componentId: layerOptions.dataSetComponentId,
          url,
          format,
          epsg,
          where,
          filterId,
          exportUserId,
          ...params
        });
      })
      .then((exportResult) => {
        return exportResult;
      })
      .finally(() => {
        context.commit(SET_LOADING, {
          componentId: layerComponentId,
          value: false
        });
      });
  },

  [createBufferZones](context, { layerComponentId, bufferZoneValues }) {
    const { dataSetComponentId } = context.getters[getOptions](layerComponentId);
    const { filterId, where, params } = context.getters[getOptions](layerComponentId);
    const additionalParams = { _config_filter_id: filterId, where, ...params };
    return context
      .dispatch(createBufferZonesDataset, { componentId: layerComponentId, dataSetComponentId, bufferZoneValues, additionalParams })
      .then((data) => {
        const bufferLayerId = data[0].component_id;
        return bufferLayerId;
      })
      .catch((error) => {
        let message = 'Системная ошибка. Обратитесь к разработчикам';
        if (error.message) {
          message = error.message;
        }
        notify({ message, width: 'auto' }, 'warning', 3000);
        return null;
      });
  },

  [cloneLayer](context, { layerComponentId }) {
    const { dataSetComponentId, text } = context.getters[getOptions](layerComponentId);
    const componentName = context.state.items[layerComponentId].componentName;
    const bufferZoneValues = {
      sideValue: 'both',
      roundedValue: 'mitre',
      endcapValue: 'square',
      unionValue: false,
      zoneValue: 0
    };
    const additionalParams = {
      caption: `${text}_копия`,
      name: `${componentName}_copy`
    };
    const datasetName = 'CONFIG.PRCLONE_LAYER';
    return context
      .dispatch(createBufferZonesDataset, {
        componentId: layerComponentId,
        dataSetComponentId,
        bufferZoneValues,
        additionalParams,
        datasetName
      })
      .then((data) => {
        const bufferLayerId = data[0].r_component_id;
        return bufferLayerId;
      })
      .catch((error) => {
        let message = 'Системная ошибка. Обратитесь к разработчикам';
        if (error.message) {
          message = error.message;
        }
        notify({ message, width: 'auto' }, 'warning', 3000);
        return null;
      });
  },

  [loadFeatureData](context, { layerComponentId, ...param }) {
    const layerOptions = context.getters[getOptions](layerComponentId);

    context.commit(SET_LOADING, {
      componentId: layerComponentId,
      value: true
    });

    return getLoadChildComponentsPromise(context, layerComponentId)
      .then(() => {
        return context.dispatch(getData, {
          componentId: layerOptions.dataSetComponentId,
          ...param
        });
      })
      .finally(() => {
        context.commit(SET_LOADING, {
          componentId: layerComponentId,
          value: false
        });
      });
  },

  [loadFeatures](context, { layerComponentId, featuresIds }) {
    return getLoadChildComponentsPromise(context, layerComponentId).then(() => {
      const { keyField, dataSetComponentId, parentGisId } = context.getters[getOptions](layerComponentId);
      const { dataSetName, defaultParam } = context.getters[getDatasetOptions](dataSetComponentId);
      const gisOptions = context.state.componentOptions[parentGisId] || {};

      let param;
      if (featuresIds.length === 1) {
        param = { ...defaultParam, _config_is_geojson: true, _config_dataset: dataSetName };
        param[keyField] = featuresIds[0];
      } else {
        param = [];
        for (const id of featuresIds) {
          param.push({ ...defaultParam, [keyField]: id, _config_is_geojson: true, _config_dataset: dataSetName });
        }
      }

      param.year = gisOptions.selectedDate ? new Date(gisOptions.selectedDate).getFullYear() : new Date().getFullYear();

      return jsonRPC('getData', param).then((response) => {
        let features = [];
        // Если объект всего один, то возвращается FeatureCollection
        // Если объектов больше 1, то возвращается массив FeatureCollection
        if (Array.isArray(response)) {
          features = response.reduce((accum, fc) => accum.concat(fc.features), []);
        } else {
          features = response.features;
        }
        if (!Array.isArray(features)) {
          console.warn('Запрос к объектам ', featuresIds, 'вернул некорректное значение features:', features);
          features = [];
        }
        features.forEach((feat) => {
          // Исключаем для проверки геометрии типы с точкой и коллекцией
          if (feat.geometry && feat.geometry.type !== 'Point' && feat.geometry.type !== 'GeometryCollection') {
            //Проход по всем контурам исправление геометрии
            walkContours(feat.geometry.coordinates, (part, index, parent, path) => {
              if (!part[0]) {
                //Нет ни одной точки, удаляем контур
                parent.splice(index, 1);
              }
            });
          }
          feat.id = feat.properties[keyField];
        });
        return features;
      });
    });
  },

  [loadGeoJson](context, { layerComponentId, ...param }) {
    const layerOptions = context.getters[getOptions](layerComponentId);
    if (layerOptions.subType) {
      //Нестандартный тип geojson слоя, загрузка по собственному алгоритму
      return Promise.resolve();
    }
    return getLoadChildComponentsPromise(context, layerComponentId).then(() => {
      return context.dispatch(getData, {
        componentId: layerOptions.dataSetComponentId,
        ...param,
        _config_is_geojson: true
      });
    });
  },

  [loadValidationList](context, { componentId }) {
    const { getters, commit } = context;

    return getLoadChildComponentsPromise(context, componentId).then(() => {
      const dataSetName = getters[getDataSetName](componentId);

      return jsonRPC('getData', {
        _config_dataset: 'CONFIG.DSVALIDATION',
        dataset_code: dataSetName
      }).then((data) => {
        commit(SET_VALIDATION_LIST, {
          componentId,
          validationList: data
        });
      });
    });
  },

  [loadSelectedValidationList]({ commit }, { componentId }) {
    return jsonRPC('getData', {
      _config_dataset: 'CONFIG.DSVALIDATION_COMPONENT',
      component_id: componentId
    }).then((data) => {
      const validationIds = data.map((item) => {
        return {
          validationId: item.validation_id,
          validationType: item.validation_type
        };
      });
      const validationСonnections = data.map((item) => {
        return {
          connectionId: item.id,
          validationId: item.validation_id,
          validationType: item.validation_type
        };
      });

      commit(SET_SELECTED_VALIDATION_IDS_LIST, {
        componentId,
        selectedValidationsIdsList: validationIds
      });

      commit(SET_SELECTED_VALIDATION_IDS_LIST_FROM_DATASET, {
        componentId,
        selectedValidationsIdsList: validationСonnections
      });
    });
  },

  [loadArcGis](context, { layerComponentId, update = false }) {
    const layerOptions = context.getters[getOptions](layerComponentId);
    const { arcGisLayers, arcGisLegend } = layerOptions;

    if (arcGisLayers && arcGisLegend && !update) {
      return {
        arcGisLayers,
        arcGisLegend
      };
    }

    context.commit(SET_LOADING, {
      componentId: layerComponentId,
      value: true
    });

    return argGisGetToken(layerOptions.url, layerOptions.token, layerOptions.useProxy)
      .then((token) => {
        context.commit(SET_OPTION, {
          componentId: layerComponentId,
          optionName: 'arcGisToken',
          optionValue: token
        });
        return Promise.all([
          arcGisLoadLayers(layerOptions.url, token, layerOptions.useProxy),
          arcGisLoadLegend(layerOptions.url, token, layerOptions.useProxy)
        ]);
      })
      .then(([arcGisLayers, arcGisLegend]) => {
        context.commit(PARSE_ARC_GIS_LAYERS, {
          layerComponentId,
          arcGisLayers
        });

        context.commit(PARSE_ARC_GIS_LEGEND, {
          layerComponentId,
          arcGisLegend
        });

        return {
          arcGisLayers,
          arcGisLegend
        };
      })
      .catch(() => {
        //TODO: обработка ошибки
      })
      .finally(() => {
        context.commit(SET_LOADING, {
          componentId: layerComponentId,
          value: false
        });
      });
  },

  [getWfsData](context, { layerComponentId }) {
    const { type, url, version, typeName, maxFeatures, token, useProxy } = context.getters[getOptions](layerComponentId);
    if (type === 'wfs') {
      const params = {
        service: 'wfs',
        request: 'GetFeature',
        version: version || '2.0.0',
        outputFormat: 'application/json',
        typeName: typeName || ''
      };

      if (maxFeatures) {
        const major = +params.version.split('.')[0];
        let maxCountKey = 'maxFeatures';
        if (major > 1) {
          maxCountKey = 'count';
        }
        params[maxCountKey] = maxFeatures;
      }

      const paramsStr = Object.entries(params)
        .map(([paramName, paramValue]) => {
          return `${paramName}=${paramValue}`;
        })
        .join('&');

      let delimiter = '?';
      if (url.includes(delimiter)) {
        delimiter = '&';
      }

      const requestUrl = `${url}${delimiter}${paramsStr}`;

      let fetchOptions = {
        mode: 'cors',
        method: 'GET',
        headers: {}
      };

      if (token) {
        fetchOptions.headers.Authorization = 'Basic ' + token;
      }

      const isScanex = SCANEX_HOSTS.some((scanexHost) => {
        return url && url.indexOf(scanexHost) === 0;
      });

      let fetchUrl = requestUrl;
      if (NO_PROXY_HOSTS.every((host) => !url.includes(host))) {
        fetchUrl = useProxy ? `${process.env.VUE_APP_PROXY_SERVICE}?url=${requestUrl}` : requestUrl;
      }

      return fetch(fetchUrl, fetchOptions)
        .then((response) => {
          if (!response.ok) {
            throw response;
          }

          return isScanex ? response.text() : response.json();
        })
        .then((data) => {
          if (isScanex) {
            return jsonRPC(process.env.VUE_APP_API_FILES_SERVICE, 'NULL.NULL.CONVERTER', {
              from_extension: 'gml',
              to_extension: 'geojson',
              data
            });
          } else {
            return Promise.resolve(data);
          }
        })
        .catch(() => {
          notify({ message: `Сервис ${url} недоступен`, width: 'auto' }, 'error', 3000);
        });
    }
  },

  [uploadAndSetGeoJsonData](context, { componentId, reload = false }) {
    const { geoJsonLoadPromise, type, keyField, singleFeature, refreshDataParam } = context.getters[getOptions](componentId);
    if (['geojson', 'wfs'].includes(type) && (!geoJsonLoadPromise || reload) && !singleFeature && componentId !== TMP_LAYER_CONFIG.componentId) {
      context.commit(SET_LOADING, {
        componentId,
        value: true
      });

      if (reload) {
        context.commit(CLEAR_FEATURES, {
          layerComponentId: componentId
        });
      }

      const actionName = type === 'geojson' ? loadGeoJson : getWfsData;
      const geoJsonLoadPromise = context
        .dispatch(actionName, {
          layerComponentId: componentId,
          ...refreshDataParam
        })
        .then((data) => {
          context.commit(SET_FEATURES, {
            layerComponentId: componentId,
            features: data?.features ?? [],
            keyField
          });

          return data;
        })
        .finally(() => {
          context.commit(SET_LOADING, {
            componentId,
            value: false
          });
        });

      context.commit(SET_OPTION, {
        componentId: componentId,
        optionName: 'geoJsonLoadPromise',
        optionValue: geoJsonLoadPromise
      });

      return geoJsonLoadPromise;
    }
  },

  //Загрузка всех асинхронных ресурсов, которые потребуются для отображения WMS
  [loadWms](context, { layerComponentId, update = false }) {
    const layerOptions = context.getters[getOptions](layerComponentId);
    let { wmsLegends, token, useProxy } = layerOptions;

    //Легенды загружены?
    if (wmsLegends[0] && !update) {
      return {
        wmsLegends
      };
    }

    const layerIds = layerOptions.layers.split(',');
    const legendOptions = layerIds.map((layerId) => {
      //Всегда используем явно заданные иконки, возвращаем нулл, тогда они подставятся в компоненте легенд
      const url = layerOptions.iconUrl || layerOptions.iconCss ? null : getWmsLegendGraphicUrl(layerOptions, null, layerId);
      return {
        layerId,
        url,
        singleRule: true
      };
    });

    //Если нужна авторизация
    if (token) {
      context.commit(SET_LOADING, {
        componentId: layerComponentId,
        value: true
      });
      const promises = legendOptions.map((legendOption) => {
        return loadImageAjax(legendOption.url, token, useProxy).then((url) => {
          return {
            ...legendOption,
            url
          };
        });
      });

      Promise.all(promises)
        .then((result) => {
          context.commit(SET_WMS_LEGENDS, {
            layerComponentId,
            wmsLegends: result
          });
        })
        .finally(() => {
          context.commit(SET_LOADING, {
            componentId: layerComponentId,
            value: false
          });
        });
      return;
    }

    context.commit(SET_WMS_LEGENDS, {
      layerComponentId,
      wmsLegends: legendOptions
    });
  },

  [clearSelection](context, { layerComponentId, exceptLayerComponentIds }) {
    /**
     * Тут все ломалось:
     *
     * layerComponentId - строка
     * exceptLayerComponentIds - массив чисел
     */
    if (exceptLayerComponentIds) {
      // на всякий случай приводим layerComponentId к типу элементов массива
      const _layerComponentId = typeof exceptLayerComponentIds[0] === 'number' ? parseInt(layerComponentId) : `${layerComponentId}`;

      // чтобы сработала эта проверка
      if (exceptLayerComponentIds.includes(_layerComponentId)) {
        return;
      }
    }

    const layerOptions = context.getters[getOptions](layerComponentId);

    context.commit(CLEAR_SELECTION, {
      layerComponentId
    });

    context.commit(SET_SELECTION_GEOJSON, {
      componentId: layerComponentId
    });

    if (layerOptions.group) {
      context.getters[getChildLayerComponentIds](layerComponentId).forEach((childLayerComponentId) => {
        context.dispatch(clearSelection, {
          layerComponentId: childLayerComponentId,
          exceptLayerComponentIds
        });
      });
    }
  },

  [locate](context, { componentId, ...param }) {
    const layerOptions = context.getters[getOptions](componentId);
    const { feature, select } = param;
    delete param.select;

    if (!Object.keys(param)[0]) {
      console.error(`Вызов locate без параметров! componentId=${componentId}`);
      return;
    }

    context.commit(SET_LOADING, {
      componentId: componentId,
      value: true
    });

    if (param.coordinates) {
      const value = param.coordinates;
      const geoJson = {
        features: [],
        type: 'FeatureCollection'
      };

      if (typeof value === 'string') {
        geoJson.features.push(getFeatubeByCoordinates(value));
      } else if (typeof value === 'object') {
        if (value.type !== 'Feature') {
          return;
        }
        geoJson.features.push(value);
      }

      const selectedIds = (geoJson.features || []).map((feature) => {
        return feature.id || feature.properties[layerOptions.keyField];
      });

      if (selectedIds.length > 0) {
        //Установка выбранных идентификаторов
        context.commit(SET_SELECTED_IDS, {
          componentId,
          ids: selectedIds
        });

        context.commit(SET_SELECTION_GEOJSON, {
          componentId,
          ids: [...selectedIds]
        });
      }

      //Выполнить перемещение карты к объектам
      context.commit(SET_BOUNDS, {
        gisComponentId: layerOptions.parentGisId,
        bounds: bbox,
        zoom: param.zoom
      });

      return;
    }

    return getLoadChildComponentsPromise(context, componentId)
      .then(() => {
        if (select === false) {
          return Promise.resolve();
        } else {
          //Очистим выделение на родительской карте полностью
          return context.dispatch(gisClearSelection, {
            componentId: layerOptions.parentGisId
          });
        }
      })
      .then(() => {
        //Если параметра нет или нет датасета, ничего не делаем
        if (!param || !layerOptions.dataSetComponentId) {
          return null;
        }

        if (feature) {
          const loadedData = {
            features: Array.isArray(feature) ? feature : [feature],
            type: 'FeatureCollection'
          };

          context.commit(SET_LOADED_DATA, {
            componentId: layerOptions.dataSetComponentId,
            loadedData
          });
          return loadedData;
        }

        //Если в params для полей переданы массивы,
        //создаем where выражение
        const where = [];
        const attrToDelete = [];
        Object.keys(param)
          .filter((attr) => !attr.startsWith('_'))
          .forEach((attr) => {
            if (Array.isArray(param[attr])) {
              attrToDelete.push(attr);
              //Без этого ошибка сервера для единственного условия
              !where[0] && where.push(true);
              where.push('and');
              const expression = param[attr].reduce((acc, val, idx) => {
                acc.push([attr, '=', val]);
                acc.push('or');
                return acc;
              }, []);
              //Без этого ошибка сервера для единственного условия
              expression[0] && expression.push(false);
              where.push(expression);
            }
          });
        //Удаляем поля с массивами
        attrToDelete.forEach((attr) => delete param[attr]);
        if (where[0]) {
          param.where = where;
        }

        const requestParams = {
          componentId: layerOptions.dataSetComponentId,
          ...param,
          _config_is_geojson: true,
          //Параметр для сервера, чтобы отличить от других запросов
          _config_is_map: true
        };

        if (layerOptions.params?.cubeworkspace_id) {
          requestParams.cubeworkspace_id = layerOptions.params?.cubeworkspace_id;
        }
        //Запрос geoJson
        return context.dispatch(getData, requestParams);
      })
      .then((geoJson) => {
        if (!geoJson || !Array.isArray(geoJson.features)) {
          notify({ message: `Объект не найден`, width: 'auto' }, 'error', 5000);
          return;
        }

        //Проверка чтобы хотя бы у одного объекта была геометрия, если у объекта нет геометрии приходит feature без
        // поля geometry и проверка что бы у геометрии были координаты не пустые
        if (
          !geoJson.features.some((feature) => {
            return feature.geometry;
          }) ||
          geoJson.features.some((feature) => {
            return feature.geometry?.coordinates?.length === 0;
          }) ||
          geoJson.features.some((feature) => {
            //geometrycollection
            return (feature.geometry?.geometries || []).some((geometry) => {
              return geometry.coordinates?.length === 0;
            });
          })
        ) {
          notify({ message: `Отсутствет геометрия`, width: 'auto' }, 'error', 5000);
          return;
        }

        const selectedIds = (geoJson.features || []).map((feature) => {
          return feature.id || feature.properties[layerOptions.keyField];
        });

        //Если не получили объекты, то ничего не делаем
        if (selectedIds.length <= 0) {
          return;
        }

        //TODO: Возможно, это костыль
        if (layerOptions.type === 'geojson' && layerOptions.singleFeature) {
          //установим объекты в словарь для geojson с singleFeature
          context.commit(SET_FEATURES, {
            layerComponentId: componentId,
            features: geoJson.features || [],
            keyField: layerOptions.keyField
          });
        }

        //Включение видимости
        context.dispatch(setVisible, {
          componentId,
          visible: true
        });

        //Включение доступности
        context.commit(SET_ENABLE, {
          componentId,
          enable: true
        });

        if (select !== false) {
          //Установка выбранных идентификаторов
          context.commit(SET_SELECTED_IDS, {
            componentId,
            ids: selectedIds
          });

          context.commit(SET_SELECTION_GEOJSON, {
            componentId,
            ids: [...selectedIds]
          });
        }

        //Выполнить перемещение карты к объектам
        context.commit(SET_BOUNDS, {
          gisComponentId: layerOptions.parentGisId,
          bounds: getBounds(geoJson),
          zoom: param.zoom
        });

        // Точечные объекты и полигоны маленького размера поднимаем над таблицей
        const featureType = geoJson.features[0]?.geometry?.type;
        if (['Point', 'Polygon'].includes(featureType)) {
          const options = context.getters[getOptions](componentId);
          const { shiftPoint, shiftPolygon } = context.getters[getOptions](options.parentGisId);
          let moveY = shiftPoint;
          if (featureType === 'Polygon') {
            const polygonArea = area(geoJson);
            moveY = polygonArea < 10000 ? shiftPolygon : 0;
          }

          if (moveY) {
            const mapbox = context.getters[getMapbox](options.parentGisId);
            setTimeout(() => {
              mapbox.panBy([0, moveY], {
                duration: 0
              });
            }, 500);
          }
        }

        return geoJson;
      })
      .finally(() => {
        context.commit(SET_LOADING, {
          componentId: componentId,
          value: false
        });
      });
  },

  [layerValidation](context, { componentId, ...param }) {
    let { layerComponentId } = context.getters[getOptions](componentId);
    let selectedValidationIdsList = context.getters[getSelectedValidationIdsList](layerComponentId);
    let filterSelectedValidationIdsList = selectedValidationIdsList
      .filter((item) => item.validationType === +param.validationType)
      .map((item) => item.validationId);

    const params = {
      _config_dataset: 'CONFIG.DSRUNVALIDATION',
      validation_ids: filterSelectedValidationIdsList,
      dataset_code: param.dataset_code,
      _config_geojson: param.features
    };

    if (filterSelectedValidationIdsList.length > 0) {
      return jsonRPC('getData', params).then((data) => {
        if (data[0].r_validation) {
          notify({ message: `Проверка слоя: ${data[0].r_comment}`, width: 'auto' }, 'success', 5000);
          return { valid: true };
        } else {
          notify({ message: `Проверка слоя: ${data[0].r_comment}`, width: 'auto' }, 'error', 5000);
          return { valid: false };
        }
      });
    }
  },

  [showCard](context, { componentId, ...params }) {
    context.commit(SET_LOADING, {
      componentId,
      value: true
    });

    return getLoadChildComponentsPromise(context, componentId)
      .then(() => {
        const cardComponentId = context.getters[getCardComponentId](componentId);
        if (!cardComponentId) {
          return;
        }
        return commonActions.callComponentAction({
          componentId: cardComponentId,
          actionName: 'show',
          ...params
        });
      })
      .finally(() => {
        context.commit(SET_LOADING, {
          componentId,
          value: false
        });
      });
  },

  [loadReportImageLegend](context, { layerComponentId, reportImageGuid, reportImageId }) {
    const { type, legendStyleItems, text } = context.getters[getOptions](layerComponentId);

    switch (type) {
      case 'pkk':
      case 'arcgis':
        return context
          .dispatch(loadArcGis, {
            layerComponentId
          })
          .then(() => {
            return Object.values(legendStyleItems).map((legendStyleItem) => {
              return new ReportImageLegend({
                /**Гуид изображения для отчета */
                reportimage_guid: reportImageGuid,

                /**Идентификатор DSREPORTIMAGE */
                reportimage_id: reportImageId,

                /**Идентификатор компонента-слоя */
                component_id: layerComponentId,

                /**Название */
                component_name: text,

                /**Изображение легенды в формате base64 */
                legend_image: legendStyleItem.iconUrl,

                /**Идентификатор правила */
                rule_id: legendStyleItem.id,

                /**Название правила */
                rule_name: legendStyleItem.caption
              });
            });
          });
      case 'vector':
      case 'geojson':
      case 'wfs':
        return Object.values(legendStyleItems).map((legendStyleItem) => {
          return new ReportImageLegend({
            /**Гуид изображения для отчета */
            reportimage_guid: reportImageGuid,

            /**Идентификатор DSREPORTIMAGE */
            reportimage_id: reportImageId,

            /**Идентификатор компонента-слоя */
            component_id: layerComponentId,

            /**Название */
            component_name: text,

            /**Изображение легенды в формате base64 */
            legend_image: getIconDataUrl(legendStyleItem.mapboxLayerConfigs, null /**TODO: images из Gis */, true),

            /**Идентификатор правила */
            rule_id: legendStyleItem.id,

            /**Название правила */
            rule_name: legendStyleItem.caption
          });
        });
      case 'wms':
      case 'tile':
        return Object.values(legendStyleItems).map((legendStyleItem) => {
          let reportImageLegend = new ReportImageLegend({
            /**Гуид изображения для отчета */
            reportimage_guid: reportImageGuid,

            /**Идентификатор DSREPORTIMAGE */
            reportimage_id: reportImageId,

            /**Идентификатор компонента-слоя */
            component_id: layerComponentId,

            /**Название */
            component_name: text,

            /**Изображение легенды в формате base64 */
            legend_image: '',

            /**Идентификатор правила */
            rule_id: legendStyleItem.id,

            /**Название правила */
            rule_name: legendStyleItem.caption
          });

          toDataURL(legendStyleItem.iconUrl).then(
            (dataUrl) => {
              reportImageLegend.legend_image = dataUrl;
            },
            (error) => {
              window.console.warn('error: ', error);
            }
          );

          return reportImageLegend;
        });
    }
  },

  [applyFilter](context, { layerComponentId }) {
    //формирование карты фильтров по коду датасетов
    let { filter, where, complexFilter, filterListIds, statisticFilterKeys, constructorFilter, params } = context.getters[getFilterObject](layerComponentId);

    //Если значения фильтра пусты
    if (!filter && !where && !complexFilter && !filterListIds && !statisticFilterKeys && !constructorFilter && !params) {
      //Сброс filterId
      context.commit(SET_FILTER_ID, {
        layerComponentId,
        filterId: null
      });
      //Очистка geojson объектов
      context.commit(CLEAR_FEATURES, { layerComponentId });
      return null;
    }

    // добавляем значение FilterBuilder, если он был задано
    if (complexFilter) {
      if (where) {
        where = [where, 'and', complexFilter];
      } else {
        where = complexFilter;
      }
    }

    if (constructorFilter) {
      if (where) {
        where = [where, 'and', constructorFilter];
      } else {
        where = constructorFilter;
      }
    }

    //Значения statisticFilterKeys передаются через действие setStatisticFilterValue, вызванное на событиях диграмм в окне статистики слоя
    //При возникновении ошибок см. соответствие данных диаграмм значениям в действиях
    if (statisticFilterKeys) {
      const delimiters = ['<>', '='];
      let statisticFilter = [];
      statisticFilterKeys.forEach((item) => {
        if (statisticFilter.length > 0) {
          statisticFilter.push('or');
        }
        const key = Object.keys(item)[0];
        const value = Object.values(item)[0];
        if (key === 'fields') {
          const delimiterIndex = value.indexOf(delimiters[0]) > -1 ? 0 : 1;
          const valueArray = value.split(delimiters[delimiterIndex]);
          statisticFilter.push([valueArray[0], delimiters[delimiterIndex], valueArray[1] === 'null' ? null : valueArray[1]]);
        } else {
          statisticFilter.push(['the_geom', 'geom_detail', value]);
        }
      });

      if (where) {
        where = [where, 'and', statisticFilter];
      } else {
        where = statisticFilter;
      }
    }

    const layerOptions = context.getters[getOptions](layerComponentId);
    const parentGisId = layerOptions.parentGisId;
    const gisOptions = context.state.componentOptions[parentGisId] || {};

    //Добавление параметра с датой из таймлайна
    if (gisOptions.timeLineDateInFilter) {
      if (layerOptions.timelineDateField && gisOptions.selectedDate) {
        const timeLineDate = context.getters[getTimelineValue](layerComponentId);
        if (['params', 'all'].includes(layerOptions.timeLineFieldSendMode)) {
          params[layerOptions.timelineDateField] = timeLineDate;
        }

        if (['where', 'all'].includes(layerOptions.timeLineFieldSendMode) || !layerOptions.timeLineFieldSendMode) {
          if (where) {
            where = [where, 'and', [layerOptions.timelineDateField, '=', timeLineDate]];
          } else {
            where = [layerOptions.timelineDateField, '=', timeLineDate];
          }
        }
      }
    }

    const requestParam = {
      _config_dataset: 'BASE.DSFILTER',
      dataset: context.getters[getDataSetName](layerComponentId),
      body: {
        filter,
        where,
        complexFilter,
        list_ids: filterListIds,
        _static_filter: statisticFilterKeys,
        constructorFilter,
        params
      }
    };

    return jsonRPC('insertData', requestParam).then((data) => {
      if (data && data[0]) {
        //Установка filterId
        context.commit(SET_FILTER_ID, {
          layerComponentId,
          filterId: data[0].id
        });
        //Очистка geojson объектов
        context.commit(CLEAR_FEATURES, { layerComponentId });

        return data[0].id;
      }

      return null;
    });
  },

  [setFilterConfig](context, { layerComponentId, filterConfig }) {
    const filterComponentId = context.state.layerFilterComponentIds[layerComponentId];

    context.commit(SET_FILTER_LIST_IDS, {
      layerComponentId,
      filterListIds: filterConfig.filterListIds
    });

    context.commit(SET_STATISTIC_FILTER_KEYS, {
      layerComponentId,
      statisticFilterKeys: filterConfig.statisticFilterKeys
    });

    context.commit(SET_COMPLEX_FILTER_VALUE, {
      layerComponentId,
      value: filterConfig.complexFilter
    });

    context.commit(SET_CONSTRUCTOR_FILTER_VALUE, {
      layerComponentId,
      value: filterConfig.constructorFilter || null,
      params: filterConfig.params || null
    });

    context.commit(SET_FILTER_FIELD_VALUES, {
      layerComponentId,
      filterFieldValues: filterConfig.filter
    });

    if (filterComponentId) {
      commonActions.callComponentAction({
        componentId: filterComponentId,
        actionName: 'setFilter',
        filterValue: filterConfig.constructorFilter || null,
        params: filterConfig.params || null
      });
    }
  },

  /**Внешнее действие - создание и применение фильтра */
  [createAndApplyFilter](
    { dispatch, getters, commit },
    { componentId, cubeworkspace_id, complexFilter, filter, list_ids, _static_filter, params, where, external_filter }
  ) {
    if (
      !(
        external_filter ||
        complexFilter ||
        filter ||
        (list_ids && list_ids[0]) ||
        (_static_filter && _static_filter[0]) ||
        where ||
        external_filter ||
        (params && Object.keys(params)[0])
      )
    ) {
      //Фильтрация пустая - сбросить фильтр
      commit(SET_FILTER_ID, {
        layerComponentId: componentId,
        filterId: null
      });
      return Promise.resolve(null);
    }

    params = {
      ...params,
      ...(external_filter && { external_filter })
    };
    return dispatch(loadChildComponents, { layerComponentId: componentId })
      .then(() => {
        const param = {
          body: {
            complexFilter,
            filter,
            list_ids,
            _static_filter,
            ...(params && Object.keys(params).length > 0 && { params }),
            where
          },
          cubeworkspace_id,
          dataset: getters[getDataSetName](componentId),
          _config_dataset: 'BASE.DSFILTER'
        };

        return jsonRPC('insertData', param);
      })
      .then((data) => {
        if (data && data[0]) {
          //Установка filterId
          commit(SET_FILTER_ID, {
            layerComponentId: componentId,
            filterId: data[0].id
          });
          //Очистка geojson объектов
          commit(CLEAR_FEATURES, { layerComponentId: componentId });

          return data[0].id;
        }
      });
  },

  /**Замена элемента стиля по заданному пути. Если элемент не существует, то не добавляется.
   * styleType: mapboxLayers | mapboxHoverLayers | mapboxSelectionLayers
   * itemPath: e.g. layout/text-field
   */
  [setStyleItem](context, { componentId, styleType, itemPath, value }) {
    const layerOptions = context.getters[getOptions](componentId);
    const rules = layerOptions[styleType];
    rules.forEach((rule) => {
      const attrValue = getPropByPath(rule, itemPath.split('/'));
      if (attrValue) {
        setPropByPath(rule, itemPath.split('/'), value);
      }
    });
  },

  [updateMapboxLayers](context, { layerComponentId, layerStyles, selectionLayerStyles = [], hoverLayerStyles = [] }) {
    const layerOptions = context.getters[getOptions](layerComponentId);

    if (!layerOptions) {
      return;
    }

    if (!layerStyles[0]) {
      return;
    }

    if (['geojson', 'vector', 'wfs'].includes(layerOptions.type)) {
      // Учтем обязательное наличие source-layer у vector, и его обязательное отсутствие у geojson
      if (layerOptions.type === 'vector') {
        const sourceLayer = layerOptions.sourceLayer || layerStyles.find((style) => style['source-layer'])['source-layer'];
        layerStyles.forEach((style) => {
          if (!style['source-layer']) {
            style['source-layer'] = sourceLayer;
          }
        });
        selectionLayerStyles.forEach((style) => {
          if (!style['source-layer']) {
            style['source-layer'] = sourceLayer;
          }
        });
        hoverLayerStyles.forEach((style) => {
          if (!style['source-layer']) {
            style['source-layer'] = sourceLayer;
          }
        });
      }

      if (['geojson', 'wfs'].includes(layerOptions.type)) {
        layerStyles.forEach((style) => {
          if (style['source-layer']) {
            delete style['source-layer'];
          }
        });
        selectionLayerStyles.forEach((style) => {
          if (style['source-layer']) {
            delete style['source-layer'];
          }
        });
        hoverLayerStyles.forEach((style) => {
          if (style['source-layer']) {
            delete style['source-layer'];
          }
        });
      }

      //Установка нового стиля
      context.commit(SET_MAPBOX_LAYERS, {
        layerComponentId,
        layerStyles,
        selectionLayerStyles,
        hoverLayerStyles
      });

      //Пересоздание элементов легенды
      //TODO: возможно надо переделать на геттер?
      context.commit(INIT_LEGEND_STYLE_ITEMS, {
        layerComponentId
      });
    }

    if (layerOptions.type === 'wms' || layerOptions.type === 'tile') {
      context.commit(REPAINT_LAYER, {
        componentId: layerComponentId
      });
      //Перерисовка иконки в легенде
      context
        .dispatch(loadWms, {
          layerComponentId,
          update: true
        })
        .then(() => {
          return context.dispatch(updateComponent, {
            componentId: layerComponentId,
            componentConfigChanges: {},
            componentOptionChanges: {
              mapboxLayers: layerStyles
            }
          });
        });
      return;
    }

    //Сохранение через конструктор
    const layerStylesConfig = layerStyles.map((style) => {
      if (style.customFilter) {
        let newStyle = { ...style };
        newStyle.filter = newStyle.customFilter;
        delete newStyle.customFilter;
        return newStyle;
      }
      return style;
    });
    const selectionLayerStylesConfig = selectionLayerStyles.map((style) => {
      if (style.customFilter) {
        let newStyle = { ...style };
        newStyle.filter = newStyle.customFilter;
        delete newStyle.customFilter;
        return newStyle;
      }
      return style;
    });
    const hoverLayerStylesConfig = hoverLayerStyles.map((style) => {
      if (style.customFilter) {
        let newStyle = { ...style };
        newStyle.filter = newStyle.customFilter;
        delete newStyle.customFilter;
        return newStyle;
      }
      return style;
    });

    return context.dispatch(updateComponent, {
      componentId: layerComponentId,
      componentConfigChanges: {},
      componentOptionChanges: {
        mapboxLayers: layerStylesConfig,
        mapboxSelectionLayers: selectionLayerStylesConfig,
        mapboxHoverLayers: hoverLayerStylesConfig
      }
    });

    //Перерисовка иконки в легенде
    //legendItemFlat.styleRules = StyleOption.getHierarchicalRules(layerConfig.options.mapboxLayers,
    // layerConfig.options.mapboxSelectionLayers, layerConfig.options.mapboxLayerHoverConfigs, false, this.images,
    // layerConfig.options.geomTypes);

    //legendItemFlat.styleRules.forEach((styleRule) => {
    //Для всех стилей установим видимость слоя
    //styleRule.options.visible = legendItemFlat.visible;
    //});
  },

  [updateLayerOptions](context, { layerComponentId, options }) {
    Object.entries(options).forEach(([optionName, optionValue]) => {
      context.commit(SET_OPTION, {
        componentId: layerComponentId,
        optionName: optionName === 'geomtypes' ? 'geomTypes' : optionName,
        optionValue
      });
      if (optionName === 'mapboxLayers') {
        const visible = context.getters[isVisible](layerComponentId);
        context.commit(SET_OPTION, {
          componentId: layerComponentId,
          optionName: 'visible',
          optionValue: visible
        });
        context.commit(INIT_LEGEND_STYLE_ITEMS, {
          layerComponentId
        });
      }
    });
    return Promise.resolve(layerComponentId);
  },

  [gisLayerLoadChildLayerComponents]({ commit, dispatch, getters }, { componentId, gisComponentId, forceHideLayers }) {
    const { group, childLayersLoaded } = getters[getOptions](componentId);

    if (group && !childLayersLoaded) {
      commit(SET_LOADING, {
        componentId,
        value: true
      });

      return dispatch(loadChildLayerComponents, { componentId, gisComponentId, forceHideLayers }).finally(() => {
        commit(SET_LOADING, {
          componentId,
          value: false
        });
      });
    } else {
      return Promise.resolve();
    }
  },

  [setSearchFields](context, { componentId, searchFields }) {
    context.commit(SET_OPTION, {
      componentId: componentId,
      optionName: 'searchFields',
      optionValue: searchFields
    });
  },

  [setSearchDataLoading](context, { componentId, value }) {
    context.commit(SET_OPTION, {
      componentId: componentId,
      optionName: 'searchDataLoading',
      optionValue: value
    });
  },

  [openCalcForm](context, { componentId, objects, fieldName }) {
    const { parentGisId } = context.getters[getOptions](componentId);

    context.commit(SET_CALC_FORM_OPTION, { gisComponentId: parentGisId, option: 'layerComponentId', value: componentId });
    context.commit(SET_CALC_FORM_OPTION, { gisComponentId: parentGisId, option: 'fieldName', value: fieldName });
    context.commit(SET_CALC_FORM_OPTION, { gisComponentId: parentGisId, option: 'objects', value: objects });
    context.commit(SET_CALC_FORM_OPTION, { gisComponentId: parentGisId, option: 'visible', value: true });
  },

  [closeCalcForm](context, { componentId }) {
    const { parentGisId } = context.getters[getOptions](componentId);

    context.commit(SET_CALC_FORM_OPTION, { gisComponentId: parentGisId, option: 'layerComponentId', value: null });
    context.commit(SET_CALC_FORM_OPTION, { gisComponentId: parentGisId, option: 'fieldName', value: null });
    context.commit(SET_CALC_FORM_OPTION, { gisComponentId: parentGisId, option: 'objects', value: [] });
    context.commit(SET_CALC_FORM_OPTION, { gisComponentId: parentGisId, option: 'visible', value: false });
  },

  [refreshData](context, { componentId, ...param }) {
    context.commit(SET_OPTION, {
      componentId: componentId,
      optionName: 'refreshDataParam',
      optionValue: param
    });

    return context
      .dispatch(uploadAndSetGeoJsonData, {
        componentId,
        reload: true
      })
      .then((data) => {
        let dataBbox = null;
        if (typeof data === 'object') {
          const dataForBbox = {
            ...data,
            features: (data?.features || []).filter((item) => item.geometry?.coordinates)
          };

          dataBbox = bbox(dataForBbox);
        } else {
          window.console.warn(`Слой ${componentId} не вернул геометрию: ${data}`);
        }

        return {
          data,
          bbox: dataBbox
        };
      });
  },

  /**Пересчет стилей с интерполяцией в видимой области*/
  [calcInterpolationLayers](context, { layerComponentId, visibleFeatures }) {
    const { mapboxLayers } = context.getters[getOptions](layerComponentId);
    if (!mapboxLayers || !mapboxLayers.length || !visibleFeatures || !visibleFeatures.length) {
      context.commit(SET_OPTION, {
        componentId: layerComponentId,
        optionName: 'mapboxCalcInterpolationLayers',
        optionValue: null
      });
      context.commit(INIT_LEGEND_STYLE_ITEMS, {
        layerComponentId
      });
      return;
    }
    let mapboxCalcInterpolationLayers = [];
    mapboxLayers.forEach((layer) => {
      if (!layer.paint) {
        return;
      }
      const paintColor = layer.paint['fill-color'] || layer.paint['line-color'] || layer.paint['circle-color'];
      if (!Array.isArray(paintColor)) {
        return;
      }
      const [type, typeInterpolation, expression, ...colors] = paintColor;
      const [, fieldName] = expression;

      //поиск мин и макс среди видимых объектов
      let min = visibleFeatures[0].properties[fieldName];
      let max = visibleFeatures[0].properties[fieldName];
      visibleFeatures.forEach((feature) => {
        if (feature.properties[fieldName] < min) {
          min = feature.properties[fieldName];
        }
        if (feature.properties[fieldName] > max) {
          max = feature.properties[fieldName];
        }
      });

      if (min === max) {
        mapboxCalcInterpolationLayers.push({
          ...layer,
          paint: {
            ...layer.paint,
            [`${layer.type}-color`]: colors[1]
          }
        });
        return;
      }

      //Создание новой палитры
      //TODO: Учитывать множество шагов
      const newColors = [min, colors[1], max, colors[colors.length - 1]];
      mapboxCalcInterpolationLayers.push({
        ...layer,
        paint: {
          ...layer.paint,
          [`${layer.type}-color`]: [type, typeInterpolation, expression, ...newColors]
        }
      });
    });
    context.commit(SET_OPTION, {
      componentId: layerComponentId,
      optionName: 'mapboxCalcInterpolationLayers',
      optionValue: mapboxCalcInterpolationLayers.length ? mapboxCalcInterpolationLayers : null
    });
    context.commit(INIT_LEGEND_STYLE_ITEMS, {
      layerComponentId
    });
  },

  [editLayerObject](context, { componentId, objectId }) {
    const { parentGisId, editingObjectId } = context.getters[getOptions](componentId);

    context.commit(SET_EDIT_LAYER_OBJECT, {
      componentId,
      editingObjectId: editingObjectId === objectId ? null : objectId
    });

    context.dispatch(setEditingLayerComponentId, {
      gisComponentId: parentGisId,
      layerComponentId: editingObjectId === objectId ? null : componentId
    });
  },

  [addLayerPolygon](context, { componentId, ...param }) {
    if (!context.state.componentOptions[componentId]) {
      return;
    }

    const { value } = param;
    if (!value) {
      return;
    }

    if (typeof value === 'string') {
      const feature = getFeatubeByCoordinates(value);
      context.commit(ADD_FEATURES, { layerComponentId: componentId, features: [feature] });
      return { feature };
    }

    if (typeof value === 'object') {
      if (value.type !== 'Feature') {
        return;
      }

      context.commit(ADD_FEATURES, { layerComponentId: componentId, features: [value] });
      return { feature: value };
    }
  },

  /**
   * Загрузка списков объектов, которые доступны по датасету слоя
   * @param {*} param0
   * @param {*} param1
   * @returns
   */
  [loadObjectLists]({ dispatch, getters }, { layerComponentId, param }) {
    const layerOptions = getters[getOptions](layerComponentId);

    if (!layerOptions.dataSetComponentId) {
      return null;
    }

    return dispatch(dataSetLoadObjectLists, {
      componentId: layerOptions.dataSetComponentId,
      param
    });
  },

  [showLayerTablePopup](context, { layerComponentId }) {
    const tableComponentId = context.getters[getTableComponentId](layerComponentId);

    commonActions.callComponentAction({
      componentId: tableComponentId,
      actionName: 'show'
    });
  },

  [fillMapExtent]({ getters, dispatch }, { componentId, ...param }) {
    const options = getters[getOptions](componentId);
    const mapbox = getters[getMapbox](options.parentGisId);
    const layerBounds = getters[getLayerBounds](componentId);

    if (layerBounds) {
      mapbox.fitBounds(layerBounds, { animate: false, padding: 50 });
      return;
    }
    if (options.modelOrigin) {
      //Для 3д слоев
      mapbox.panTo(options.modelOrigin);
      return;
    }

    dispatch(loadChildComponents, { layerComponentId: componentId })
      .then(() => {
        return dispatch(loadBounds, {
          layerComponentId: componentId,
          ...param
        });
      })
      .then((loadedLayerBounds) => {
        mapbox.fitBounds(loadedLayerBounds, { animate: false, padding: 50 });
      });
  },

  /**Сохранение положения слоя в панели избранных */
  [saveFavoritePosition]({ getters, commit }, { layerComponentId, gisComponentId, value }) {
    const favoriteLayerItems = getters[getOptions](gisComponentId).favoriteLayerItems;
    const favoriteItem = favoriteLayerItems.find((item) => item.componentId === layerComponentId);
    commit(SET_FAVORITE_POSITION, { layerComponentId, value });
    favoriteItem.pos = favoriteItem.source.pos = value;
    return jsonRPC('updateData', {
      ...favoriteItem.source,
      _config_dataset: 'CONFIG.DSLAYERFAVORITE'
    }).then((result) => {
      result;
    });
  }
};

function getLoadChildComponentsPromise(context, layerComponentId) {
  const layerOptions = context.getters[getOptions](layerComponentId);
  let loadChildComponentsPromise = layerOptions.loadChildComponentsPromise;

  if (!layerOptions.loadChildComponentsPromise) {
    loadChildComponentsPromise = loadLayerChildComponents(context, layerComponentId);

    context.commit(SET_OPTION, {
      componentId: layerComponentId,
      optionName: 'loadChildComponentsPromise',
      optionValue: loadChildComponentsPromise
    });
  }

  return loadChildComponentsPromise;
}

function loadLayerChildComponents(context, layerComponentId) {
  let constructorActive = context.rootState.uiConstructor.active;

  const param = {
    _config_component_id: layerComponentId,
    _config_dataset: 'CONFIG.DSCONFIG',
    is_constructor: constructorActive
  };

  return jsonRPC(process.env.VUE_APP_API_SERVICE, 'CONFIG.DSCONFIG.GETPAGE', param)
    .then((data) => {
      const components = data.components || {};
      const eventAction = data.eventAction || [];

      //Установка массива items
      context.commit(SET_ITEMS, {
        layerComponentId,
        components,
        items: components[layerComponentId].options.items || []
      });

      if (context.state.componentOptions[layerComponentId]) {
        //Загрузка дочерних компонентов слоя может быть вызвана действиями, отрабатывающими раньше загрузки слоя
        delete components[layerComponentId];
      }

      context.commit(SET_ITEMS_AND_OPTIONS, { components });
      context.commit('setEventAction', { eventAction: eventAction || [] });
      context.dispatch('emitLayerInitializedEvent', { components });

      return data;
    })
    .catch((err) => {
      window.console.log(err.message);
    });
}

function getBounds(geoJson, locateBufferSize = 0.15) {
  return bbox(buffer(geoJson, locateBufferSize));
}

function loadFilters(context, layerComponentId) {
  const layerOptions = context.getters[getOptions](layerComponentId);

  return getLoadChildComponentsPromise(context, layerComponentId).then(() => {
    return context.dispatch(loadDataSetFilters, {
      componentId: layerOptions.dataSetComponentId
    });
  });
}

const NO_FILTER_TYPES = ['geometry', 'json', 'bigint[]'];

function filterFieldOptionsFactory(dataFields) {
  let filterFieldOptions = {};

  //Фильтрация полей
  const filteredDataFields = dataFields.filter((field) => {
    return field.is_visiblefilter && !NO_FILTER_TYPES.includes(field.fieldtype_name);
  });

  //Сортировака полей по полю pos
  const sortedFields = sortFilterFieldsByPos(filteredDataFields);

  //Формирование словаря
  sortedFields.forEach((field) => {
    const fieldOptions = new CustomFilterFieldOptions(field);
    filterFieldOptions[fieldOptions.name] = fieldOptions;
  });

  return filterFieldOptions;
}

const FILTER_FIELDS_TYPES_MAP = {
  text: 'string',
  'varchar(256)': 'string',
  bigint: 'number',
  integer: 'number',
  numeric: 'number',
  'double precision': 'number',
  'timestamp without time zone': 'datetime',
  timestamp: 'datetime',
  boolean: 'boolean'
};

function filterBuilderFieldsFactory(filterFieldOptions) {
  return filterFieldOptions.map((customFilterFieldOption) => {
    if (customFilterFieldOption.type.indexOf('timestamp') > -1) {
      return {
        dataField: customFilterFieldOption.name,
        caption: customFilterFieldOption.caption,
        dataType: FILTER_FIELDS_TYPES_MAP[customFilterFieldOption.type],
        format: 'shortDateShortTime'
      };
    }

    if (customFilterFieldOption.type !== 'dictionary') {
      return {
        dataField: customFilterFieldOption.name,
        caption: customFilterFieldOption.caption,
        dataType: FILTER_FIELDS_TYPES_MAP[customFilterFieldOption.type]
      };
    }

    const dictionaryStore = new CustomStore({
      key: 'key',
      loadMode: 'processed',
      load: (loadOptions) => {
        if (!loadOptions.take || loadOptions.skip === undefined) {
          return;
        }

        let params = {
          _config_dataset: customFilterFieldOption.dataSet,
          _config_serverside: true,
          limit: loadOptions.take,
          offset: loadOptions.skip,
          orderby: {
            selector: customFilterFieldOption.joinFieldName,
            desc: false
          },
          mode: 'paging'
        };

        if (loadOptions.searchValue) {
          params.where = [customFilterFieldOption.joinFieldName, loadOptions.searchOperation, loadOptions.searchValue];
        }
        return jsonRPC('getData', params).then((result) => {
          return result.data.map((dataItem) => {
            return {
              name: dataItem[customFilterFieldOption.joinFieldName],
              key: dataItem[customFilterFieldOption.joinFieldKey]
            };
          });
        });
      },
      byKey: (value) => {
        let params = {
          _config_dataset: customFilterFieldOption.dataSet,
          where: [customFilterFieldOption.joinFieldKey, '=', value]
        };
        return jsonRPC('getData', params).then((data) => {
          return {
            name: data[0][customFilterFieldOption.joinFieldName],
            key: data[0][customFilterFieldOption.joinFieldKey]
          };
        });
      }
    });

    return {
      caption: customFilterFieldOption.caption,
      filterOperations: ['=', '<>', 'isblank', 'isnotblank'],
      editorTemplate: 'lookupEditor',
      dataField: customFilterFieldOption.name,
      editorOptions: {
        width: 165,
        valueExpr: 'key',
        displayExpr: 'name',
        itemTemplate: (data, index, element) => {
          element.innerHTML = `<span title="${data.name}">${data.name}</span>`;
        },
        dataSource: dictionaryStore
      },
      lookup: {
        valueExpr: 'key',
        displayExpr: 'name',
        dataSource: dictionaryStore
      }
    };
  });
}

const toDataURL = (url) =>
  fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    );

/**
 * Преключение видимости слоя
 * @param {*} state
 * @param {*} param1, isRootCall - корневой вызов: для компонента был нажат глазик
 */
function setLayerVisible(context, { componentId, visible, layerComponentIds, isRootCall }) {
  const layerOptions = context.state.componentOptions[componentId];

  if (visible && !verifyVisibleLayersCount(context, layerOptions.parentGisId, componentId)) {
    return;
  }

  if (layerOptions.group) {
    let loadChildActionPromise;

    if (visible) {
      //Загрузка содержимого группы
      loadChildActionPromise = context.dispatch(gisLayerLoadChildLayerComponents, {
        componentId,
        gisComponentId: layerOptions.parentGisId
      });
    } else {
      loadChildActionPromise = Promise.resolve();
    }

    loadChildActionPromise.then(() => {
      const childLayerComponentIds = context.getters[getChildLayerComponentIds](componentId);
      childLayerComponentIds.forEach((childLayerComponentId) => {
        const { parentGisId, type, visible: formerVisible } = context.getters[getOptions](childLayerComponentId);
        setLayerVisible(context, {
          componentId: childLayerComponentId,
          visible: layerComponentIds ? layerComponentIds.includes(componentId) || layerComponentIds.includes(childLayerComponentId) : visible,
          layerComponentIds
        });

        // Для слоев ПКК включаем/выключаем идентификацию
        // Добавлено условие - не переключать на идентификацию, если в слое уже установлено visible:true в конструкторе
        if (type === 'pkk' && !(formerVisible === true && visible === true)) {
          context.commit(SET_SELECTED_MOUSE_TOOL, {
            gisComponentId: parentGisId,
            buttonName: visible ? OBJECT_IDENTIFICATION : SELECT
          });
        }
      });
      if (visible) {
        //При включении видимости установим фильтр по умолчанию
        context.dispatch(initLayersDefaultFilters, { layerComponentsIds: childLayerComponentIds });
      }
    });

    return;
  }

  //Не группа
  if (layerOptions.legendStyleItems) {
    const legendStyleItemIds = Object.values(layerOptions.legendStyleItems)
      .filter((legendStyleItem) => {
        return legendStyleItem.parentId === componentId;
      })
      .map((legendStyleItem) => {
        return legendStyleItem.id;
      });
    if (legendStyleItemIds.length > 0) {
      legendStyleItemIds.forEach((legendStyleItemId) => {
        context.dispatch(setLegendStyleItemVisible, {
          componentId,
          legendStyleItemId,
          visible
        });
      });
      if (visible && isRootCall) {
        //Добавление в недавно используемые
        context.dispatch(addLayerToRecent, { gisComponentId: layerOptions.parentGisId, layerComponentId: componentId });
        //При включении видимости установим фильтр по умолчанию
        context.dispatch(initLayersDefaultFilters, { layerComponentsIds: [componentId] });
      }
    } else {
      context.commit(SET_VISIBLE, { componentId, visible });
      setLayerSavedOption(componentId, layerOptions.parentGisId, { visible }, context.getters[getOptions](layerOptions.parentGisId));
    }
    if (!visible) {
      const animationLayer = AnimatedCarsLayer.getExistingInstance(componentId);
      if (animationLayer) {
        animationLayer.stop();
      }
    }
  }
}

function loadImageAjax(url, token, useProxy) {
  const fetchOptions = {
    headers: {
      Authorization: 'Basic ' + token,
      'x-requested-with': 'XMLHttpRequest'
    }
  };

  let proxyUrl = url;
  if (NO_PROXY_HOSTS.every((host) => !url.includes(host))) {
    proxyUrl = useProxy ? `${process.env.VUE_APP_PROXY_SERVICE}?url=${url}` : url;
  }

  return fetch(proxyUrl, fetchOptions)
    .then((response) => {
      return response.blob();
    })
    .then((blob) => {
      return URL.createObjectURL(blob);
    });
}

/**
 * Функция преобразует настройки фильтра слоя, которые приходят из датасета CONFIG.DSFILTER
 * @param {*} configFilter
 * @param {*} layerComponentId
 * @returns
 */
export function refactorConfigFilter(configFilter, layerComponentId) {
  let filter = deepCopy(configFilter);
  let complexFilter = null;
  let filterListIds = null;
  let statisticFilterKeys = null;
  let constructorFilter = null;
  let params = null;
  let filterLayerComponentId = null;

  //если новый формат
  if (configFilter.hasOwnProperty('filter')) {
    filter = deepCopy(configFilter.filter);
    complexFilter = configFilter.complexFilter;
    filterListIds = configFilter.list_ids;
    statisticFilterKeys = configFilter._static_filter;
    constructorFilter = configFilter.constructorFilter;
    params = configFilter.params;
    filterLayerComponentId = configFilter.layerComponentId || null;
    //Если передан id целевого слоя
    if (filterLayerComponentId && filter[filterLayerComponentId]) {
      //Изменим целевой слой на текущий
      filter[layerComponentId] = filter[filterLayerComponentId];
      if (layerComponentId !== filterLayerComponentId) {
        delete filter[filterLayerComponentId];
      }
    }
  }

  //Если в фильтре нет текущего слоя
  const firstLayerComponentId = Object.keys(filter)[0];
  if (!filter[layerComponentId] && firstLayerComponentId) {
    //Присвоим текущему слою значение фильтра первого слоя
    filter[layerComponentId] = filter[firstLayerComponentId];
    delete filter[firstLayerComponentId];
  }

  return {
    //Словарь, где датасет - ключ, а значения - фильтрующие выражения по слоям с параметрами пересечения
    filter,

    //Фильтр сделанный с помощью filterBuilder Дополнительно на вкладке "Атрибуты"
    complexFilter,

    //Список идентификаторов списков объектов, списки исключения со знаком "-"
    filterListIds,

    //Массив значений фильтра статистики слоя
    statisticFilterKeys,

    //Фильтрующее выражение из сконструированного фильтра
    constructorFilter,

    //Все значения формы фильтра, созданной на конструкторе
    //(в том числе поля, которые не должны входить в фильтрующее выражение constructorFilter).
    //Сделано по заказу здрава для крайне сложной фильтрации
    params
  };
}

function getFeatubeByCoordinates(value) {
  let coordinates = [];
  let arr = [];

  value.split(',').forEach((item) => {
    arr.push(+item);
    if (arr.length === 2) {
      coordinates.push(arr);
      arr = [];
    }
  });

  if (coordinates.length === 0) {
    return;
  }

  const id = new Guid().toString();
  return {
    type: 'Feature',
    id: id,
    properties: {
      id: id
    },
    geometry: {
      type: 'Polygon',
      coordinates: [coordinates]
    }
  };
}
