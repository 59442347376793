import { CustomB3dmLayer } from '../3d/customB3dmLayer';

export const mapboxLayerFactory = {
  tile: createMapboxTileLayer,
  wms: createMapboxWmsLayer,
  wmts: createMapboxWmtsLayer,
  wcs: createMapboxWcsLayer,
  wfs: createMapboxGeoJsonLayer,
  geojson: createMapboxGeoJsonLayer,
  pkk: createMapboxPkkLayer,
  vector: createVectorLayer,
  gltf: createGltfLayer,
  b3dm: createB3dmLayer
};

function createMapboxTileLayer(layerConfig) {
  const layerOptions = layerConfig.options || {};
  const id = layerConfig.componentId;
  const { show, value } = layerConfig.skyOptions;

  // Если слой неба отключен, то устанавливаем дефолтное значение
  // Иначе в завимисимости от угла солнца настраиваем "светлость" карты
  const rasterBrightnessMax = show ? 1 - value / 180 : 1;

  return [
    {
      id,
      source: `${layerConfig.componentId}_tile`,
      type: 'raster',
      maxzoom: layerOptions.maxZoom || 22,
      minzoom: layerOptions.minZoom || 0,
      metadata: {},
      paint: {
        'raster-brightness-max': rasterBrightnessMax,
        'raster-opacity': layerOptions.rasterOpacity
      }
    }
  ];
}

function createMapboxWmtsLayer(layerConfig) {
  const layerOptions = layerConfig.options || {};
  const id = layerConfig.componentId;

  return [
    {
      id,
      source: `${layerConfig.componentId}_tile`,
      type: 'raster',
      maxzoom: layerOptions.maxZoom || 22,
      minzoom: layerOptions.minZoom || 0,
      metadata: {},
      'source-layer': layerOptions.layers[0],
      paint: {
        'raster-opacity': layerOptions.rasterOpacity
      }
    }
  ];
}

function createMapboxWcsLayer(layerConfig) {
  const layerOptions = layerConfig.options || {};
  const id = layerConfig.componentId;

  return [
    {
      id,
      source: `${layerConfig.componentId}_tile`,
      type: 'raster',
      maxzoom: layerOptions.maxZoom || 22,
      minzoom: layerOptions.minZoom || 0,
      metadata: {},
      paint: {
        'raster-opacity': layerOptions.rasterOpacity
      }
    }
  ];
}

function createMapboxWmsLayer(layerConfig) {
  const layerOptions = layerConfig.options || {};
  const id = layerConfig.componentId;

  return [
    {
      id,
      source: `${layerConfig.componentId}_tile`,
      type: 'raster',
      maxzoom: layerOptions.maxZoom || 22,
      minzoom: layerOptions.minZoom || 0,
      metadata: {},
      paint: {
        'raster-opacity': layerOptions.rasterOpacity
      }
    }
  ];
}

function createMapboxPkkLayer(layerConfig) {
  const layerOptions = layerConfig.options || {};
  const id = layerConfig.componentId + '_pkk';

  return [
    {
      id,
      source: `${layerConfig.componentId}_tile`,
      type: 'raster',
      maxzoom: layerOptions.maxZoom || 22,
      minzoom: layerOptions.minZoom || 0,
      metadata: {},
      paint: {
        'raster-opacity': layerOptions.rasterOpacity
      }
    }
  ];
}

function createMapboxGeoJsonLayer(layerConfig) {
  const layerOptions = layerConfig.options || {};

  const mapboxLayers = createMapboxMapLayers(layerOptions.mapboxCalcInterpolationLayers || layerOptions.mapboxLayers || []);
  //const visibility = layerOptions.visible ? 'visible' : 'none';

  return mapboxLayers.map((mapboxLayer, index) => {
    //let layout = mapboxLayer.layout || {};
    //layout.visibility = visibility;

    //const filter = getFilter(mapboxLayer.filter, mapboxLayer.geometry);
    return {
      ...mapboxLayer,
      //layout,
      id: `${layerConfig.componentId}_${index}`,
      source: `${layerConfig.componentId}_geojson`,
      //filter,
      metadata: {}
    };
  });
}

function createVectorLayer(layerConfig) {
  const layerOptions = layerConfig.options || {};
  const mapboxLayers = createMapboxMapLayers(layerOptions.mapboxCalcInterpolationLayers || layerOptions.mapboxLayers || []);

  return mapboxLayers.map((mapboxLayer, index) => {
    let resultMapboxLayerConfig = {
      ...mapboxLayer,
      'source-layer': mapboxLayer['source-layer'] || layerOptions.sourceLayer,
      id: `${layerConfig.componentId}_${index}`,
      source: `${layerConfig.componentId}_${layerOptions.type}`,
      //filter: getFilter(mapboxLayer.filter, mapboxLayer.geometry),
      metadata: {
        layerComponentId: layerConfig.componentId
      }
    };

    //Если слой(стиль) попал в правило для кластеризации, то добавляем фильтр чтобы он применялся только если есть cluster_size
    if (mapboxLayer.metadata?.isCluster) {
      resultMapboxLayerConfig.filter = ['all', ['has', 'cluster_size']];
    }

    if (mapboxLayer.metadata?.opacity) {
      resultMapboxLayerConfig.metadata.opacity = true;
    }

    return resultMapboxLayerConfig;
  });
}

const MAPBOX_LAYER_TYPES = ['backgroud', 'fill', 'line', 'line', 'symbol', 'raster', 'circle', 'fill-extrusion', 'heatmap', 'hillshade', 'sky'];

/**
 * Генерация слоев для mapbox с учетом обводки полигонального слоя
 * @param {any[]} mapboxLayersConfig
 * @returns {Array} массив слоев для mapbox, где слой fill с обводкой разделен на два слоя с типом fill и line
 */
export function createMapboxMapLayers(mapboxLayersConfig = []) {
  let mapboxLayers = [];
  mapboxLayersConfig
    .filter((mapboxLayerConfig) => {
      return MAPBOX_LAYER_TYPES.includes(mapboxLayerConfig.type);
    })
    .forEach((mapboxLayerConfig) => {
      //ПОлучаем "чистую" кнофигурацию слоя mapbox (без посторонних полей)
      const geometry = mapboxLayerConfig.geometry;
      let clearMapboxLayerConfig = getClearMapboxLayerConfig(mapboxLayerConfig);
      clearMapboxLayerConfig.filter = getFilter(clearMapboxLayerConfig.filter, geometry);

      if (clearMapboxLayerConfig.type === 'symbol') {
        if (!clearMapboxLayerConfig.layout) {
          clearMapboxLayerConfig.layout = {};
        }
        //По умолчанию разрешаем перекрытие иконок
        clearMapboxLayerConfig.layout['icon-allow-overlap'] = clearMapboxLayerConfig.layout['icon-allow-overlap'] ?? true;

        //Для кластера разрешим перекрытие текста
        if (mapboxLayerConfig.metadata?.isCluster) {
          clearMapboxLayerConfig.layout['text-allow-overlap'] = clearMapboxLayerConfig.layout['text-allow-overlap'] ?? true;
        }

        if (mapboxLayerConfig.opacity) {
          clearMapboxLayerConfig.metadata.opacity = true;
        }
      }

      //TODO: служебные поля caption, timeStamp, name, geometry, записать в metadata

      //Для неполигональных слоев добавляем чистый конфиг как есть
      if (mapboxLayerConfig.type !== 'fill') {
        if (mapboxLayerConfig.metadata?.isCluster) {
          clearMapboxLayerConfig.filter = ['all', ['has', 'cluster_size']];
        }

        mapboxLayers.push(clearMapboxLayerConfig);
        return;
      }

      //Для полигональных слоев задаем слой заливки и слой обводки
      let fillPaint = {};
      let fillLayout = {};
      let strokePaint = {};
      let strokeLayout = {};
      let layout = {};

      //Наполнение стилей рисования обводки и заливки
      Object.entries(mapboxLayerConfig.paint || {}).forEach(([key, value]) => {
        if (key.startsWith('line')) {
          strokePaint[key] = value;
          return;
        }
        fillPaint[key] = value;
      });

      //Если обводки нет, то берем только чистый конфиг
      if (!Object.keys(strokePaint).length) {
        mapboxLayers.push(clearMapboxLayerConfig);
        return;
      }

      //Наполнение свойств layout
      Object.entries(mapboxLayerConfig.layout || {}).forEach(([key, value]) => {
        if (key.startsWith('line')) {
          strokeLayout[key] = value;
          return;
        } else if (key.startsWith('fill')) {
          fillLayout[key] = value;
          return;
        }

        layout[key] = value;
      });

      let fillLayerConfig = {
        ...clearMapboxLayerConfig,
        paint: fillPaint,
        layout: {
          ...layout,
          ...fillLayout
        }
      };

      let strokeLayerConfig = {
        ...clearMapboxLayerConfig,
        paint: strokePaint,
        layout: {
          ...layout,
          ...strokeLayout
        },
        type: 'line'
      };

      if (mapboxLayerConfig.id) {
        strokeLayerConfig.id = `${mapboxLayerConfig.id}_stroke`;
      }
      mapboxLayers.push(fillLayerConfig, strokeLayerConfig);
    });

  return mapboxLayers;
}

const MAPBOX_LAYER_CONFIG_FIELDS = ['filter', 'id', 'layout', 'maxzoom', 'metadata', 'minzoom', 'paint', 'source', 'source-layer', 'type'];
/**
 * Метод пытается сформировать из объекта "чистый" объект с настройками слоя mapbox
 * @param {Object} mapboxLayerConfig объект с настройками, который может содержать лишние поля
 * @returns {Object} объект с настройками слоя mapbox без лишних полей
 */
export function getClearMapboxLayerConfig(mapboxLayerConfig) {
  let clearMapboxLayerConfig = {};

  if (!mapboxLayerConfig) {
    return clearMapboxLayerConfig;
  }

  MAPBOX_LAYER_CONFIG_FIELDS.forEach((fieldName) => {
    if (mapboxLayerConfig.hasOwnProperty(fieldName)) {
      clearMapboxLayerConfig[fieldName] = mapboxLayerConfig[fieldName];
    }
  });

  //Иногда на сервере сохраняется paint или layout в виде пустого массива
  //Пустой объект в пустой массив преобразует бэк
  //Понять в какой момент на сервер идет пустой объект не удалось
  //поэтому чистим такие массивы
  ['paint', 'layout'].forEach((fieldName) => {
    if (Array.isArray(clearMapboxLayerConfig[fieldName])) {
      delete clearMapboxLayerConfig[fieldName];
    }
  });

  return clearMapboxLayerConfig;
}

function getFilter(filter, geometry) {
  let geomFilter = null;
  if (geometry && geometry.length) {
    const geometryTypes = geometry
      .filter((item) => !item.includes('Multi'))
      .map((item) => {
        if (item === 'Line') {
          item = 'LineString';
        }
        return [item, `Multi${item}`];
      })
      .flat();
    geomFilter = ['match', ['geometry-type'], geometryTypes, true, false];
  }
  let commonFilter;
  if (filter && geomFilter) {
    commonFilter = ['all', filter, geomFilter];
  } else {
    commonFilter = filter || geomFilter || ['all'];
  }
  return commonFilter;
}

import mapboxgl from 'mapbox-gl';
import * as THREE from 'three';
import GLTFLoader from './GLTFLoader';

function createGltfLayer(layerConfig) {
  const layerOptions = layerConfig.options || {};

  if (!layerOptions.modelOrigin) {
    return [];
  }

  // parameters to ensure the model is georeferenced correctly on the map
  let modelOrigin = layerOptions.modelOrigin;
  let modelAltitude = layerOptions.modelAltitude || 0;
  let modelRotate = layerOptions.modelRotate || [0, 0, 0];

  let modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(modelOrigin, modelAltitude);

  // transformation parameters to position, rotate and scale the 3D model onto the map
  let modelTransform = {
    translateX: modelAsMercatorCoordinate.x,
    translateY: modelAsMercatorCoordinate.y,
    translateZ: modelAsMercatorCoordinate.z,
    rotateX: modelRotate[0],
    rotateY: modelRotate[1],
    rotateZ: modelRotate[2],
    /* Since our 3D model is in real world meters, a scale transform needs to be
     * applied since the CustomLayerInterface expects units in MercatorCoordinates.
     */
    scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits()
  };

  const customLayer = {
    id: layerConfig.componentId,
    type: 'custom',
    renderingMode: '3d',
    onAdd: function (map, gl) {
      this.camera = new THREE.Camera();
      this.scene = new THREE.Scene();

      const light = new THREE.AmbientLight(0xffffff, 1.25);
      this.scene.add(light);

      // use the three.js GLTF loader to add the 3D model to the three.js scene
      let loader = new GLTFLoader();
      loader.load(
        layerOptions.url,
        function (gltf) {
          this.scene.add(gltf.scene);
        }.bind(this)
      );
      this.map = map;

      // use the Mapbox GL JS map canvas for three.js
      this.renderer = new THREE.WebGLRenderer({
        canvas: map.getCanvas(),
        context: gl,
        antialias: true
      });

      this.renderer.autoClear = false;
    },
    render: function (gl, matrix) {
      let rotationX = new THREE.Matrix4().makeRotationAxis(new THREE.Vector3(1, 0, 0), modelTransform.rotateX);
      let rotationY = new THREE.Matrix4().makeRotationAxis(new THREE.Vector3(0, 1, 0), modelTransform.rotateY);
      let rotationZ = new THREE.Matrix4().makeRotationAxis(new THREE.Vector3(0, 0, 1), modelTransform.rotateZ);

      let m = new THREE.Matrix4().fromArray(matrix);
      let l = new THREE.Matrix4()
        .makeTranslation(modelTransform.translateX, modelTransform.translateY, modelTransform.translateZ)
        .scale(new THREE.Vector3(modelTransform.scale, -modelTransform.scale, modelTransform.scale))
        .multiply(rotationX)
        .multiply(rotationY)
        .multiply(rotationZ);

      this.camera.projectionMatrix = m.multiply(l);
      this.renderer.resetState();
      this.renderer.render(this.scene, this.camera);
      this.map.triggerRepaint();
    }
  };

  return [customLayer];
}

function createB3dmLayer(layerConfig) {
  const layerOptions = layerConfig.options || {};

  const customLayer = new CustomB3dmLayer(layerConfig.componentId, layerOptions);

  return [customLayer];
}

export const mapboxLayerSelectionFactory = {};
