<template>
  <!--eslint-disable-->
  <div id="app">
    <router-view />
    <ConstructorButton
      class="app__open-constructor-button"
      v-if="showConstructorButton"
      icon="fas fa-tools"
      @click="openConstructor"
      title="Открыть страницу в режиме конструирования"
    />
    <DxLoadPanel
      v-if="isLoading"
      :showIndicator="true"
      :showPane="true"
      :shading="false"
      :closeOnOutsideClick="false"
      message="Операция выполняется. Пожалуйста подождите."
      :visible="true"
      :wrapperAttr="loadPanelAttributes"
    ></DxLoadPanel>
    <DxLoadPanel
      v-if="this.isInit"
      :showIndicator="true"
      :showPane="true"
      :shading="false"
      :closeOnOutsideClick="false"
      message="Инициализация приложения"
      :visible="true"
    ></DxLoadPanel>

    <!-- Пока отключаю так как работает неправильно
    TODO: вернуть когда починим
    <DxPopup
      class="auth-reg-form-popup"
      :animation="null"
      :resizeEnabled="false"
      :showCloseButton="false"
      title="Авторизация"
      container="#app"
      :width="400"
      height="auto"
      :visible="isShowLoginPopup"
    >
    <template #titleTemplate>
      <PopupTitleTemplate
        text="Авторизация"
        :showCloseButton="false"
      />
    </template>
      <div class="auth-reg-form-popup__content">
        <div class="auth-reg-form-popup__attention">
          <img src="@/assets/exclamation-triangle-2.svg" />
          <div class="auth-reg-form-popup__text">Время сессии истекло или произошел выход из приложения. Для продолжения работы необходимо повторно авторизоваться.</div>
        </div>
        <AuthForm
          caution=""
          licenseUrl=""
          personalDataUrl=""
          @loggedIn="onLoggedIn"
        ></AuthForm>
      </div>
    </DxPopup>
    -->
  </div>
</template>

<script>
import DxLoadPanel from 'devextreme-vue/load-panel';
//import DxPopup from 'devextreme-vue/popup';
//import AuthForm from '@/components/AuthRegForm/AuthForm';
import ConstructorButton from './ConstructorButton';
import { NEED_LOGIN_CODES } from '../api/jsonRpcCodes';
import loginMixin from './mixins/login';
import { getCookie } from '@/utils/cookie';
import notify from 'devextreme/ui/notify';
//import PopupTitleTemplate from './Containers/PopupTitleTemplate';

export default {
  name: 'App',

  mixins: [loginMixin],

  components: {
    DxLoadPanel,
    ConstructorButton
    //DxPopup,
    //AuthForm,
    //PopupTitleTemplate
  },
  data() {
    return {
      tokenCheckInterval: null,
      loadPanelAttributes: {
        class: 'app-load-panel-is-loading'
      }
    };
  },
  computed: {
    isShowLoginPopup() {
      return this.$store.getters['session/getShowLoginPopup'];
    },
    showConstructorButton() {
      return !this.$store.state.uiConstructor.active && this.$store.getters['session/userIsAdmin'];
    },
    isLoading() {
      return this.$store.getters['isLoading'];
    }
  },

  created() {
    //Если мы зашли на форму входа, то ничего не делаем
    // if (this.$route.path === '/login') {
    //   return;
    // }

    this.startTokenCheck();

    this.$store
      .dispatch('session/getUserData')
      .then((userData) => {
        this.$store.dispatch('session/setIsAuthenticated', { isAuthenticated: true });

        //Установка информации о портале
        this.$store.dispatch('portal/setPortalConfig', {
          portalConfig: userData.portal
        });

        //Если задан маршрут к странице то оставляем его как есть
        if (this.$route.params.url || this.$route.params.childUrl || this.$route.params.componentId) {
          return;
        }

        //Изменение роута только после загрузки данных портала
        this.goToDefaultPath();
      })
      .catch((err) => {
        console.log('err', err);
        if (!NEED_LOGIN_CODES.includes(err.code)) {
          return;
        }

        this.$store.commit('session/setShowLoginPopup', { show: false });

        if (process.env.VUE_APP_GUEST_SESSION === true || process.env.VUE_APP_GUEST_SESSION === 'true') {
          //Создание гостевой сессии
          this.createGuestSession()
            .then(() => {
              return this.goToCurrentPath() || this.goToDefaultPath();
            })
            .catch(() => {
              this.guestSessionErrorNotify();
            });
        } else {
          const redirectToPath = this.$route.path !== '/' ? this.$route.path : '';
          console.log('redirectToLogin', redirectToPath);
          this.goToLogin(redirectToPath);
        }
      });
  },

  beforeDestroy() {
    this.stopTokenCheck();
  },

  methods: {
    onLoggedIn() {
      this.$store.commit('session/setShowLoginPopup', { show: false });
    },

    openConstructor() {
      const routeParams = this.$route.params;
      let targetUrl = '/constructor';

      if (routeParams.url) {
        if (routeParams.childUrl) {
          targetUrl += `/${routeParams.childUrl}`;
        } else {
          targetUrl += `/${routeParams.url}`;
        }
      }
      targetUrl += `?pageFullUrl=${this.$route.path}`;

      window.open(targetUrl, '_blank');
    },

    startTokenCheck() {
      const routeParams = this.$route.params;
      if (!routeParams.url || routeParams.url === 'login') {
        return;
      }
      this.tokenCheckInterval = setInterval(() => {
        const token = getCookie('token');
        if (!token) {
          notify({ message: `Токен устарел, выполните повторный вход`, width: 'auto' }, 'error', 2147483647);
          this.stopTokenCheck();
        }
      }, 2000);
    },

    stopTokenCheck() {
      clearInterval(this.tokenCheckInterval);
    }
  }
};
</script>

<style lang="scss">
/*Стили для dxDataGrid */
@import '@/styles/data-grid.scss';

html,
body,
#app {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: 'Helvetica Neue', 'Segoe UI', Helvetica, Verdana, sans-serif;
  font-size: 12px;
  /*Скрываем полосы прокрутки */
  -ms-overflow-style: none; /* IE и Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  /*Правка нижней панели dxPopup */
  .dx-popup-bottom {
    background-color: transparent;
  }
}

.app__open-constructor-button {
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 202;
}

.draggable-container {
  min-height: 26px;
}

.drag-helper {
  padding: 2px;
  border: 1px solid var(--base-border-color);
  border-radius: var(--base-border-radius);
  position: absolute;
  z-index: 9999;
  max-height: 50px;
  max-width: 150px;
  min-width: 80px;
  min-height: 26px;
  overflow: hidden;

  & > .dx-field {
    margin: 0;
    display: flex;

    & > .dx-field-label {
      float: none;
      width: auto;
      flex: 0 0 auto;
    }
    & > .dx-field-value {
      float: none;
      width: auto;
      flex: 1 1 auto;
    }
  }
}

.draggable {
  &_drag-started {
    display: none !important;
  }
}

.droppable {
  background-color: var(--base-hover-color);
  & > * {
    visibility: hidden;
  }
}

.fake-draggable-child {
  height: 26px;
}

.label-width-counter.dx-field {
  position: absolute;
  visibility: hidden;
  height: auto;
  width: auto;
  white-space: nowrap;

  & > .dx-field-label {
    float: none;
    width: auto;
  }
}

.server-operation-popups {
  position: absolute;
  visibility: hidden;
}

.auth-reg-form-popup {
  &__content {
    font-size: 14px;
  }
  &__attention {
    padding: 10px;
    text-align: center;
  }
  &__text {
    padding: 10px 0;
  }
}

.app-load-panel-is-loading {
  z-index: 10000 !important;
}
</style>
